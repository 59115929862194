import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import { Typography } from "@material-ui/core";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { useParams } from "react-router";
import useAPI from "useAPI";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import moment from "moment";
import Card from "@material-ui/core/Card";
import history from "utils/history";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  ...styles,

  inputLabel: {
    color: "#008BE8",
    font: "normal normal bold 16px/19px Lato",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  sidetext: {
    color: "#324152",
    float: "left",
    paddingLeft: "10px",
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
}));

const handleBack = () => {
  history.push("/notifications");
};

function SessionDetail() {
  const classes = useStyles();

  const api = useAPI();
  const [sessionDetail, setSessionDetail] = React.useState([""]);
  let { id } = useParams();

  React.useEffect(() => {
    api
      .get("/session-detail/" + id)
      .then((res) => {
        setSessionDetail(res.data);
      })
      .catch((e) => console.error(e));
  }, []);
  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align="center">
        {sessionDetail["vehicle"] && (
          <Card className={classes.root} variant="outlined">
            <Typography variant="h5" component="h2">
              Parking Details
            </Typography>
            <CardContent>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                xs={12}
                sm={12}
                md={12}
                align="center"
                spacing={0}
              >
                <Grid item xs={6} sm={6}>
                  <Typography className={classes.sidetext}>Vehicle</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ color: "#324152" }}>
                    {sessionDetail["vehicle"].make} -{" "}
                    {sessionDetail["vehicle"].model}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="row"
                // alignItems="center"
                xs={12}
                sm={12}
                md={12}
                // align="center"
                spacing={0}
              >
                <Grid item xs={6} sm={6}>
                  <Typography className={classes.sidetext}>
                    Start time
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ color: "#324152" }}>
                    {moment(sessionDetail.start_time).format(
                      "MM/DD/YYYY HH:mm:ss"
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="row"
                alignItems="center"
                xs={12}
                sm={12}
                md={12}
                align="center"
                spacing={0}
              >
                <Grid item xs={6} sm={6}>
                  <Typography className={classes.sidetext}>End time</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ color: "#324152" }}>
                    {sessionDetail.end_time
                      ? moment(sessionDetail.end_time).format(
                          "MM/DD/YYYY HH:mm:ss"
                        )
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="row"
                alignItems="center"
                xs={12}
                sm={12}
                md={12}
                align="center"
                spacing={0}
              >
                <Grid item xs={6} sm={6}>
                  <Typography className={classes.sidetext}>
                    Amount Paid
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ color: "#324152" }}>20 USD</Typography>
                </Grid>
              </Grid>
            </CardContent>

            <Button
              onClick={handleBack}
              color="primary"
              variant="contained"
              fullWidth
              style={{
                marginTop: 20,
                color: "white",
              }}
            >
              Back
            </Button>
          </Card>
        )}
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default SessionDetail;
