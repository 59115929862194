import React from "react";

import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { Typography, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import TicketService from "../../services/TicketService";
import useAPI from "useAPI";
import ConfirmationNumberTwoToneIcon from "@material-ui/icons/ConfirmationNumberTwoTone";

// import Autocomplete from "@material-ui/lab/Autocomplete";

// const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  // ...styles,
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    color: "#324152",
    marginTop: 20,
    marginBottom: 80,
  },
  tabStyle: { color: "#324152", marginTop: 20 },
  fab: {
    position: "absolute",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

function TicketList() {
  const classes = useStyles();

  const api = useAPI();
  const [tickets, setTickets] = React.useState([]);

  const fetchTickets = async () => {
    const resposne = await TicketService.getTicketList();
    setTickets(resposne);
  };

  React.useEffect(() => {
    TicketService.init(api);
    fetchTickets();
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const viewTicket = (token) => {
    const url = `ticket/${token}`;
    openInNewTab(url);
  };

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Typography variant="h5" style={{ color: "#324152" }}>
          My Tickets
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align="center">
        <div className={classes.root}>
          <List>
            {tickets &&
              tickets.map((ticket) => (
                <ListItem
                  divider
                  key={ticket.id}
                  button
                  value={ticket}
                  style={{
                    // backgroundColor:
                    //   ticket.status === "succeeded" ? green[100] : red[100],
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <ListItemText
                    primary={`  Plate: ${ticket.plate} `}
                    primaryTypographyProps={{
                      style: { color: "#111", fontSize: 14 },
                    }}
                    secondary={
                      <React.Fragment>
                        Phone: {ticket.phone}
                        <br />
                        {/* Date: {getDate(ticket.parkingSession.start_time)} */}
                        <br />
                        Amount($): {ticket.total_amount || "-"}
                        {/* Plate: {ticket.plate} */}
                        <br />
                        Duration: {ticket.duration || "-"}
                        {ticket.duration &&
                          (ticket.duration > 1 ? " minutes" : " minute")}
                        <br />
                        Vehicle Status:{" "}
                        {String(ticket.vehicle_status).toUpperCase()}
                        <br />
                        Payment Status:{" "}
                        {String(ticket.payment_status).toUpperCase()}
                        <br />
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => viewTicket(ticket.token)}>
                      <ConfirmationNumberTwoToneIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </div>
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default TicketList;
