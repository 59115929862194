import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  contentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "3",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    padding: "0 15px",
    width: "100%",
    maxWidth: "880px",
  },
  title: {
    textDecoration: "none",
    fontWeight: "300",
    marginTop: "30px",
    marginBottom: "25px",
    minHeight: "32px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    "& small": {
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
    subTitle: {
      fontSize: "2.25rem",
      marginTop: "0",
      marginBottom: "8px",
    },
  },
});

export default function ErrorPage(props) {
  const classes = useStyles();
  const query = new URLSearchParams(window.location.search);
  const { logout } = useAuth0();

  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>
            {String(query.get("error")).toUpperCase()}
          </h1>
          <h2 className={classes.subTitle}>
            {String(query.get("error_description")).toUpperCase()}
          </h2>
          <h2 className={classes.subTitle}>{props.message}</h2>
          <Button
            color="warning"
            onClick={() => {
              logout({
                returnTo: window.location.origin,
              });
            }}
          >
            Back to login
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ErrorPage.defaultProps = {
  message: null,
};
ErrorPage.propTypes = {
  message: PropTypes.string,
};
