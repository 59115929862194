import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import SubscriptionService from "../services/SubscriptionService";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import car from "../assets/img/car.svg";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import history from "utils/history";
import Loading from "components/Loading";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import useAPI from "useAPI";

const useStyles = makeStyles({
  ...styles,
  root: {
    width: "100%",
    // maxWidth: 460,
    position: "relative",
    // overflow: "auto",
    // maxHeight: 500,
    paddingBottom: "5%",
    marginBottom: "5%",
  },
  container: {
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
    marginBottom: "0%",
  },
});

function VehicleStatus() {
  const classes = useStyles();
  const [currentBooking, setCurrentBooking] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const api = useAPI();

  const [parkTime, setParkTime] = React.useState("");

  const updateTime = (startTime, endTime) => {
    var ms = moment(startTime, "DD/MM/YYYY HH:mm:ss").diff(
      moment(endTime, "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);
    setParkTime(Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss"));
  };

  const fetchCurrentBooking = async () => {
    await SubscriptionService.getUserSubscription().then((res) => {
      setCurrentBooking(res);
      setIsLoading(false);
    });
  };
  React.useEffect(() => {
    SubscriptionService.init(api);
    fetchCurrentBooking();
  }, []);

  React.useEffect(() => {
    if (currentBooking && currentBooking.updated_at) {
      const intervalRef = setInterval(function () {
        var now = moment();
        var then = moment(currentBooking.updated_at);
        updateTime(now, then);
      }, 1000);
      return () => {
        clearInterval(intervalRef);
      };
    }
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <GridContainer justify="center" className={classes.container}>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Paper elevation={5}>
          {/* <Card className={classes.root} variant="outlined"> */}

          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="h2">
              currentBooking Details
            </Typography> */}

            <CardHeader
              action={
                // <IconButton aria-label="settings">
                //   <HistoryIcon />
                // </IconButton>
                <Tooltip title="My Parking History" aria-label="settings">
                  <Fab
                    color="primary"
                    className={classes.fab}
                    onClick={() => {
                      history.push("/parking-history");
                    }}
                  >
                    <LocalParkingIcon />
                  </Fab>
                </Tooltip>
              }
              title="Vehicle Status"
              subheader={currentBooking.token}
            />

            <img src={car} alt="Vehicle Status" width="70%" />
            <List>
              <ListItem>
                <ListItemText
                  primary={`${currentBooking.vehicle.make} | ${currentBooking.vehicle.model}`}
                  secondary="Vehicle"
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText
                  primary={
                    currentBooking.vehicle_type +
                    " (" +
                    currentBooking.vehicle.color +
                    ")"
                  }
                  secondary="Vehicle Type (color)"
                />
                <ListItemText
                  primary={currentBooking.vehicle.plate}
                  secondary="Plate Number"
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText
                  primary={currentBooking.plan.plan_name}
                  secondary="Plan Name"
                />
                <ListItemText
                  primary={
                    <Chip
                      label={String(
                        currentBooking.vehicle_status
                      ).toUpperCase()}
                      variant="outlined"
                      color={
                        currentBooking.vehicle_status === "retrieved"
                          ? "primary"
                          : "secondary"
                      }
                    />
                  }
                  secondary="Vehicle Status"
                  style={{ marginLeft: "10%" }}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText
                  primary={moment(currentBooking.start_date).format(
                    "MM/DD/YYYY HH:mm:ss"
                  )}
                  secondary={`Next Billing Date (${moment(
                    currentBooking.next_billing_date
                  ).format("MM/DD/YYYY HH:mm:ss")})`}
                />
              </ListItem>
              <Divider component="li" />
              {currentBooking.vehicle_status === "parked" && (
                <ListItem>
                  <ListItemText
                    primary={moment(currentBooking.updated_at).format(
                      "MM/DD/YYYY HH:mm:ss"
                    )}
                    secondary="Parked Time"
                  />
                  <ListItemText primary={parkTime} secondary="Duration" />
                </ListItem>
              )}
              {currentBooking.vehicle_status === "retrieved" && (
                <ListItem>
                  <ListItemText
                    primary={moment(currentBooking.end_time).format(
                      "MM/DD/YYYY HH:mm:ss"
                    )}
                    secondary="Retrieved Time"
                  />
                  <ListItemText
                    primary={"$" + currentBooking.total_amount.toFixed(2)}
                    secondary="Amount"
                  />
                </ListItem>
              )}{" "}
            </List>
          </CardContent>
        </Paper>
      </GridItem>
    </GridContainer>
  );
}

export default VehicleStatus;
