import React from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import { Box, Typography, Button } from "@material-ui/core";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useParams } from "react-router";
import VehicleService from "services/VehicleService";
import useAPI from "useAPI";
// import { useLocation } from "react-router-dom";
import history from "utils/history";
import types from "Reducer/types";
import { Context } from "Store";
import CardService from "services/CardService";
import CarouselService from "services/CarouselService";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  ...styles,
  customTextField: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px",
    flexBasis: "auto",
  },
  inputLabel: {
    color: "#008BE8",
    font: "normal normal bold 16px/19px Lato",
  },
  float: {
    margin: theme.spacing(1),
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  floatPin: {
    margin: theme.spacing(1),
    top: "auto",
    right: "auto",
    bottom: 30,
    left: 20,
    position: "fixed",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ParkingInfo() {
  const classes = useStyles();
  const [saving, setSaving] = React.useState(false);
  const [vehicle, setVehicle] = React.useState({});
  const { state, dispatch } = React.useContext(Context);
  const [hasDefaultCard, setHasDefaultCard] = React.useState(false);
  const [defaultCard, setDefaultCard] = React.useState({});
  // const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [openCarouselError, setopenCarouselError] = React.useState(false);
  const [buttonMessage, setButtonMessage] = React.useState("Park");
  const [carouselErrorMessage] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [carouselMessage, setCarouselMessage] = React.useState("");
  const [progress, setProgress] = React.useState(0);

  let { id } = useParams();
  const api = useAPI();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep + 1 === steps.length) {
      setTimeout(() => {
        setOpen(false);
        setButtonMessage("Stow Vehicle");
      }, 500);
    }
  };

  function getSteps() {
    return [
      "Vehicle is off",
      "Vehicle is in park",
      "Keys and necessary belongings are out of the vehicle",
      "Doors, trunk, tailgate are closed",
      "Antennas are stowed",
      "Car lights are off",
      "There are no people or pets in vehicle",
    ];
  }

  React.useEffect(() => {
    if (state.parked) {
      history.replace("/");
    }
    CardService.init(api);
    CarouselService.init(api);
    CardService.getUserDefaultCard().then((res) => {
      if (res) {
        setHasDefaultCard(true);
        setDefaultCard(res);
      } else {
        history.push("/park/vehicle/" + id + "/card/new");
      }
    });
  }, []);

  const fetchVehicle = async () => {
    await VehicleService.getVehicleById(id).then((res) => {
      if (res) {
        setVehicle(res);
      }
    });
  };

  React.useEffect(() => {
    VehicleService.init(api);
    fetchVehicle();
  }, []);

  React.useEffect(() => {
    const selectedCarousel = JSON.parse(localStorage.selectedCarousel || {});
    if (selectedCarousel && selectedCarousel.nick_name) {
      setCarouselMessage(`Please Drive to ${selectedCarousel.nick_name}`);
    } else if (selectedCarousel && selectedCarousel.id) {
      setCarouselMessage(`Please Drive to selected carousel`);
    }
  }, []);

  const handleSave = () => {
    setSaving(true);
    api
      .post("park", {
        vehicleId: id,
        userLocationId: localStorage.userLocationId,
        userPin: localStorage.userPin,
      })
      .then(() => {
        api
          .get("user")
          .then((res) => {
            let sysUser = res.data;
            Object.keys(sysUser).forEach((key) => {
              sysUser[key] = sysUser[key] ? sysUser[key] : "";
            });
            dispatch({ type: types.SET_USER, payload: sysUser });
          })
          .catch((e) => console.error(e));
        api
          .get("current-session")
          .then((res) => {
            dispatch({
              type: types.SET_CURRENT_PARKING_SESSION,
              payload: res.data,
            });
            // if (res.data) {
            dispatch({
              type: types.SET_PARKING,
              payload: true,
            });
            // } else {
            //   dispatch({
            //     type: types.SET_PARKING,
            //     payload: false,
            //   });
            // }
          })
          .catch((e) => console.error(e));

        const timer = setInterval(() => {
          setProgress((oldProgress) => {
            if (oldProgress === 100) {
              return 100;
            }
            return oldProgress + 3.33;
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          api
            .get("current-session")
            .then((res) => {
              console.log("after", res.data);
              dispatch({
                type: types.SET_CURRENT_PARKING_SESSION,
                payload: res.data,
              });
              if (res.data) {
                dispatch({
                  type: types.SET_PARKED,
                  payload: true,
                });
              } else {
                dispatch({
                  type: types.SET_PARKED,
                  payload: false,
                });
              }
            })
            .catch((e) => console.error(e));

          history.replace(`/`);
        }, 30000);
      });
  };

  return (
    <AuthenticatedContainer>
      <>
        <Dialog
          open={openCarouselError}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Please Wait"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {carouselErrorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setopenCarouselError(false);
              }}
              color="primary"
            >
              OK
            </Button>
            {saving && (
              <CircularProgress size={14} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>

        <GridItem xs={12} sm={12} md={8} align="center">
          <Typography variant="h5" style={{ color: "#324152", padding: 15 }}>
            Your Parking Carousel Information
          </Typography>
        </GridItem>

        <GridItem xs={12} sm={12} md={8} align="center">
          <Typography
            variant="body1"
            style={{
              padding: 15,
              color: "green",
            }}
          >
            {`${carouselMessage} | Your Parking PIN is: ${state.user.assignedPin}`}
          </Typography>
        </GridItem>

        <GridItem xs={12} sm={12} md={8} align="center">
          <Typography variant="h5" style={{ color: "#324152", padding: 15 }}>
            Parking vehicle information
          </Typography>
        </GridItem>

        {(vehicle && vehicle.make && (
          <GridItem xs={12} sm={12} md={8} align="center">
            <Typography
              variant="body1"
              style={{
                padding: 15,
                color: "green",
              }}
            >
              {`${vehicle.make} - ${vehicle.model} | ${vehicle.color}`}
            </Typography>
          </GridItem>
        )) ||
          null}

        {(hasDefaultCard && defaultCard.name && (
          <GridItem xs={12} sm={12} md={8} align="center">
            <Typography
              variant="body1"
              style={{
                padding: 15,
                color: "#111",
              }}
            >
              {`Payment Method: ${defaultCard.name}`}
            </Typography>
          </GridItem>
        )) ||
          null}
        <GridItem>
          {/* <Box pb={5} align="center" style={{ color: "#111" }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {`+ Press green button to start parking process.`}
            </Typography>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {`+ Wait until garage door is being opened.`}
            </Typography>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {`+ Provide your pin to parking attendant.`}
            </Typography>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {`+ Be patient while parking.`}
            </Typography>
          </Box> */}
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Safety Check"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent>
                        <div className={classes.actionsContainer}>
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              className={classes.button}
                            >
                              {activeStep === steps.length - 1
                                ? "Finish"
                                : "Next"}
                            </Button>
                          </div>
                        </div>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  <Typography>Thank you for safety check.</Typography>
                </Paper>
              )}
              {saving && (
                <CircularProgress
                  size={14}
                  className={classes.buttonProgress}
                />
              )}
            </DialogActions>
          </Dialog>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Box pb={5} className={classes.inputLabel}>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              disabled={saving}
              fullWidth
              style={{
                marginTop: 20,
                color: "white",
              }}
            >
              {saving ? "Parking Vehicle..." : buttonMessage}
            </Button>
            {saving ? (
              <LinearProgress variant="determinate" value={progress} />
            ) : null}
            {saving && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Box>
        </GridItem>
      </>
    </AuthenticatedContainer>
  );
}

export default ParkingInfo;
