import defaultState from "Store/defaultState";
import types from "./types";

const Reducer = (state, action) => {
  if (state === null) {
    localStorage.removeItem("localState");
    return defaultState;
  }

  console.log(state, action);
  switch (action.type) {
    case types.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case types.SET_USER_PIN:
      return {
        ...state,
        user: { ...state.user, assignedPin: action.payload },
      };
    case types.SET_PARKING:
      return {
        ...state,
        parking: action.payload,
      };
    case types.SET_PARKED:
      return {
        ...state,
        parked: action.payload,
      };
    case types.SET_RETRIEVING:
      return {
        ...state,
        retrieving: action.payload,
      };
    case types.SET_CURRENT_PARKING_SESSION:
      return {
        ...state,
        parkingSession: action.payload,
      };
    case types.SET_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      };
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case types.SET_DISCOUNT_AMOUNT:
      return {
        ...state,
        discountamount: action.payload,
      };
    case types.SET_SUBSCRIPTION_BOOKING:
      return {
        ...state,
        hasSubscriptionBooking: action.payload,
      };
    case types.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case types.SET_SUBSCRIBED:
      return {
        ...state,
        subscribed: action.payload,
      };
    case types.SET_TNC_CHANGE:
      return {
        ...state,
        tncChange: action.payload,
      };
    case types.SET_PLAN:
      return {
        ...state,
        plan: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;
