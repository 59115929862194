import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const verifyOtp = async (otp) => {
  try {
    let cancel;
    const response = await api.put(
      "/verify-otp",
      { otp },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const OtpService = {
  init,
  verifyOtp,
};

export default OtpService;
