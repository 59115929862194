import React from "react";

import { Typography } from "@material-ui/core";
import GridItem from "./Grid/GridItem";
import SectionCarousel from "views/Components/Sections/SectionCarousel";

export default function HomeScreenWithoutLogin() {
  return (
    <GridItem xs={12} sm={12} md={12} align="center">
      <Typography variant="h3" color="primary">
        Welcome to Stack Mobility
      </Typography>
      <SectionCarousel
        slides={[
          {
            image: require("assets/img/parking/parking03.jpg"),
            title: "Parking 1",
            location: "New York, United States",
          },
          {
            image: require("assets/img/parking/parking02.jpg"),
            title: "Parking 2",
            location: "California, United States",
          },
          {
            image: require("assets/img/parking/parking01.jpg"),
            title: "Parking 3",
            location: "New Jersy, United States",
          },
        ]}
      />
    </GridItem>
  );
}
