import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Loading from "../../components/Loading";
import styles from "assets/jss/material-kit-react/views/landingPage.js";

import CarouselService from "../../services/CarouselService";
import useAPI from "useAPI";
import { useParams } from "react-router-dom";
import history from "utils/history";

const useStyles = makeStyles(styles);

export default function CarouselScannedProcess() {
  const classes = useStyles();
  let { token } = useParams();
  const api = useAPI();

  const fetchCarousel = async () => {
    await CarouselService.getCarouselByToken(token).then((res) => {
      localStorage.setItem("userLocationId", res.location_id);
      localStorage.setItem("carouselId", res.id);
      localStorage.setItem("scanned", true);
      history.push("/");
    });
  };
  React.useEffect(() => {
    CarouselService.init(api);
    fetchCarousel();
  }, []);
  return (
    <div className={classes.container}>
      <Loading />;
    </div>
  );
}
