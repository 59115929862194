import React from "react";
import { Context } from "Store";
// core components
import GridItem from "components/Grid/GridItem.js";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { Typography } from "@material-ui/core";
import "react-credit-cards/es/styles-compiled.css";
import CardForm from "./CardForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PlanCard from "../PlanCard";
import { getConfig } from "../../config";
import { useLocation } from "react-router-dom";

const config = getConfig();
const stripePromise = loadStripe(config.stripePublishKey);
function PaymentCard() {
  const location = useLocation();
  const { state } = React.useContext(Context);
  const { plan } = state;

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Typography variant="h5" style={{ color: "#324152" }}>
          {(location.state &&
            location.state.isRedirectFromPlan === true &&
            plan &&
            "Payment Details") ||
            "Add Card"}
        </Typography>
        {location.state &&
          location.state.isRedirectFromPlan === true &&
          plan && <PlanCard />}
      </GridItem>
      <Elements stripe={stripePromise}>
        <CardForm />
      </Elements>
    </AuthenticatedContainer>
  );
}

export default PaymentCard;
