import React from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { Button } from "@material-ui/core";
// import { usePosition } from "./usePosition";
export const usePosition = () => {
  const [position, setPosition] = React.useState({});
  const [error, setError] = React.useState(null);

  const onChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error) => {
    setError(error.message);
  };
  React.useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      console.log("Not Supported");
      setError("Geolocation is not supported");
      return;
    }
    let watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);
  return { ...position, error };
};
function TestMap() {
  const openMapInNewTab = (lat, lon) => {
    let url = `https://maps.google.com/?q=${lat},${lon}`;
    window.open(url, "_blank").focus();
  };
  const { latitude, longitude, error } = usePosition();

  return (
    <AuthenticatedContainer>
      <GridItem
        xs={12}
        sm={12}
        md={8}
        align="center"
        style={{ paddingBottom: 10 }}
      >
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            openMapInNewTab(22.364686, 73.2774488);
          }}
          color="primary"
        >
          Vadodara
        </Button>
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={8}
        align="center"
        style={{ paddingBottom: 10 }}
      >
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            openMapInNewTab(21.205927, 72.9915062);
          }}
          color="primary"
        >
          Surat
        </Button>
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={8}
        align="center"
        style={{ paddingBottom: 10 }}
      >
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            openMapInNewTab(22.2252884, 70.3913134);
          }}
          color="primary"
        >
          Jamanagar
        </Button>
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={8}
        align="center"
        style={{ paddingBottom: 10 }}
      >
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            console.log(latitude, longitude);
            if (!error) {
              openMapInNewTab(latitude, longitude);
            } else {
              alert("Map Not Supported");
            }
          }}
          color="secondary"
        >
          My Location
        </Button>
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default TestMap;
