import * as React from "react";
import { Grid, Button, makeStyles, CircularProgress } from "@material-ui/core";
import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC,
} from "./commonTextFields";
import { CardNumberElement } from "@stripe/react-stripe-js";
import {
  // CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import CardService from "../../services/CardService";
import SubscriptionService from "../../services/SubscriptionService";
import useAPI from "useAPI";
import { Context } from "Store";
import { useParams, useLocation } from "react-router-dom";
import types from "Reducer/types";
import history from "utils/history";
import Snackbar from "@material-ui/core/Snackbar";
// import Alert from "@material-ui/lab/Alert";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CreditCardDetail() {
  const { state, dispatch } = React.useContext(Context);
  const {
    parked,
    hasSubscriptionBooking,
    subscribed,
    paymentMethod,
    plan,
  } = state;
  const api = useAPI();
  const classes = useStyles();
  const elements = useElements();
  const [saving, setSaving] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const stripe = useStripe();
  const [cardState, setCardState] = React.useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: null,
    expiredError: null,
    cvcError: null,
  });
  const [error, setError] = React.useState("");
  const [isFromPark, setIsFromPark] = React.useState(false);

  let { id } = useParams();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.includes("park")) {
      setIsFromPark(true);
      if (parked) {
        history.replace("/");
      }
    }
    fetchCurrentBooking();
  }, [api]);

  const onElementChange = (field, errorField) => ({
    complete,
    error = { message: null },
  }) => {
    console.log("1111111111111", field, errorField, complete, error);
    setCardState({
      ...cardState,
      [field]: complete,
      [errorField]: error.message,
    });
  };

  const fetchCurrentBooking = async () => {
    SubscriptionService.init(api);
    await SubscriptionService.getSubscriptionBooking().then((res) => {
      if (res) {
        if (res.payment_status === "paid") {
          dispatch({
            type: types.SET_SUBSCRIBED,
            payload: true,
          });
        } else {
          dispatch({
            type: types.SET_SUBSCRIBED,
            payload: false,
          });
        }
        console.log(
          "paymentcard",
          parked,
          hasSubscriptionBooking,
          subscribed,
          paymentMethod
        );
      }
    });
  };

  const addStripeCard = async () => {
    // if (!cardNumberError && !expiredError && cvcError) {
    // const cardElement = elements.getElement(CardElement);
    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      console.log("[error]", error);
    } else {
      setSaving(true);
      CardService.init(api);
      await CardService.storeCard(paymentMethod).then((res) => {
        setOpen(true);
        setSaving(false);
        console.log("[PaymentMethod]", paymentMethod, res);
        if (hasSubscriptionBooking) {
          dispatch({
            type: types.SET_PAYMENT_METHOD,
            payload: true,
          });
          if (!subscribed && plan) {
            setTimeout(() => {
              // redirect on confirmation screen
              history.push({
                pathname: "/confirm-reserve",
                // state: { isRedirectFromCard: true },
              });
            }, 1000);
          } else {
            setTimeout(() => {
              history.push("/payment-cards");
            }, 1000);
          }
        } else if (isFromPark) {
          setTimeout(() => {
            history.push("/park/carousel-detail" + id);
          }, 1000);
        } else {
          setTimeout(() => {
            history.push("/payment-cards");
          }, 1000);
        }
        setSaving(false);
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { cardNumberError, expiredError, cvcError } = cardState;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <StripeTextFieldNumber
          error={Boolean(cardNumberError)}
          labelErrorMessage={cardNumberError}
          onChange={onElementChange("cardNumberComplete", "cardNumberError")}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeTextFieldExpiry
          error={Boolean(expiredError)}
          labelErrorMessage={expiredError}
          onChange={onElementChange("expiredComplete", "expiredError")}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeTextFieldCVC
          error={Boolean(cvcError)}
          labelErrorMessage={cvcError}
          onChange={onElementChange("cvcComplete", "cvcError")}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        {error && error}
      </Grid>
      {/* <Button
        color="primary"
        variant="contained"
        onClick={() => addStripeCard()}
        fullWidth
        disabled={saving}
      >
        {"Add Card"}
      </Button> */}
      <Grid item xs={12} sm={12}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => addStripeCard()}
          fullWidth
          disabled={saving}
        >
          {"Add Card"}
        </Button>
        {saving && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Grid>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity="success" onClose={handleClose}>
            Card Added Successfully
          </Alert>
        </Snackbar>
      </div>
    </Grid>
  );
}
