import React from "react";

export default function TermsAndConditions() {
  return (
    <>
      In order to use our service you must agree to our{" "}
      <a href="https://www.stakmobility.com/terms/" target="_blank">
        Terms and Conditions
      </a>{" "}
      and{" "}
      <a href="https://www.stakmobility.com/privacy/" target="_blank">
        Privacy Policy
      </a>
      .
    </>
  );
}
