/* eslint-disable react/display-name */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row } from "reactstrap";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import useAPI from "useAPI";
import SubscriptionService from "services/SubscriptionService";
import { ListItem, ListItemText, Typography } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import moment from "moment";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const ParkingHistory = () => {
  const api = useAPI();
  const [parkingHistory, setParkingHistory] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [lastItem, setLastItem] = React.useState(null);
  const classes = useStyles();

  const fetchParkingHistory = async () => {
    SubscriptionService.getParkingHistory().then((res) => {
      console.log(res);
      setTotal(res.length);
      setParkingHistory(res);
    });
  };

  const getTime = (notificationDateTime) => {
    return moment(notificationDateTime).fromNow();
  };

  React.useEffect(() => {
    SubscriptionService.init(api);
    fetchParkingHistory();
  }, [api]);

  const renderLastItem = (item) => {
    return (
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            {getTime(item.start_time)}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot
            color={item.end_time ? "primary" : ""}
            style={item.end_time ? {} : { background: "green", color: "black" }}
          >
            <DriveEtaIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography className="text-muted">
              <b>
                {moment(item.start_time).format("MM/DD/YYYY HH:mm:ss")}
                {" - "}
                {item.end_time
                  ? moment(item.end_time).format("MM/DD/YYYY HH:mm:ss")
                  : "Parked"}
              </b>
            </Typography>
            <Typography variant="body2" className="text-muted">
              {item.duration}
              {item.end_time ? " Minute(s)" : ""}
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  };
  const renderParkingHistory = (item, index) => {
    console.log(index, index ? ", " : "");

    return (
      <ListItem
        divider
        key={item.id}
        button
        value={item}
        style={{
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <ListItemText
          primary={`${moment(item.start_time).format("MM/DD/YYYY HH:mm:ss")} - 
          ${
            item.end_time
              ? moment(item.end_time).format("MM/DD/YYYY HH:mm:ss")
              : "Parked"
          }`}
          primaryTypographyProps={{
            style: { color: "#111", fontSize: 14 },
          }}
          secondary={
            <React.Fragment>
              {getTime(item.start_time)} | {String(item.status).toUpperCase()}
            </React.Fragment>
          }
        />
      </ListItem>
    );
  };

  return (
    <AuthenticatedContainer>
      <Container className="mb-5">
        <Row>
          <GridItem align="center" className="mb-2">
            <Typography
              variant="h5"
              color="primary"
              style={{ color: "#394253" }}
            >
              My Parking History
            </Typography>
          </GridItem>
          <GridItem align="center" className="mb-2">
            {parkingHistory.map((notification, index) => {
              return renderParkingHistory(notification, index);
            })}
          </GridItem>
        </Row>
      </Container>
    </AuthenticatedContainer>
  );
};

export default ParkingHistory;
