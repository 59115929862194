import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getPlans = async () => {
  try {
    let cancel;
    const response = await api.get("/subscription-plans", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getUserSubscription = async () => {
  try {
    let cancel;
    const response = await api.get("/user-subscription", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getParkingHistory = async () => {
  try {
    let cancel;
    const response = await api.get("/parking-history", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const createPlan = async (id) => {
  try {
    let cancel;
    const response = await api.post("/plan/subscribe/" + id, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getSubscriptionBooking = async () => {
  try {
    let cancel;
    const response = await api.get("/subscription-booking", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const notifyAttendant = async (data) => {
  try {
    let cancel;
    const response = await api.post("/notify-attendant", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const SubscriptionService = {
  init,
  getPlans,
  getUserSubscription,
  getParkingHistory,
  createPlan,
  getSubscriptionBooking,
  notifyAttendant,
};

export default SubscriptionService;
