import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import { useParams } from "react-router-dom";
import TicketService from "../services/TicketService";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import car from "../assets/img/car.svg";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Paper from "@material-ui/core/Paper";
import CardActions from "@material-ui/core/CardActions";
import axios from "axios";
import RefreshIcon from "@material-ui/icons/Refresh";
import moment from "moment";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";

import Checkout from "./OpenCheckout";
import { IconButton } from "@material-ui/core";
import { getConfig } from "config";
import Loading from "components/Loading";

const useStyles = makeStyles({
  ...styles,

  inputLabel: {
    color: "#008BE8",
    font: "normal normal bold 16px/19px Lato",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  sidetext: {
    color: "#324152",
    float: "left",
    paddingLeft: "10px",
  },
  root: {
    width: "100%",
    // maxWidth: 460,
    position: "relative",
    overflow: "auto",
    // maxHeight: 600,
    paddingBottom: "5%",
    marginBottom: "5%",
  },
  media: {
    height: 140,
  },
});

const { apiOrigin = "http://localhost:3001" } = getConfig();

function TicketDetail() {
  const classes = useStyles();
  const [ticket, setTicket] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  let { id } = useParams();

  const [parkTime, setParkTime] = React.useState("");

  const updateTime = (startTime, endTime) => {
    var ms = moment(startTime, "DD/MM/YYYY HH:mm:ss").diff(
      moment(endTime, "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);
    setParkTime(Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss"));
  };

  function getDuration(startTime, endTime) {
    // start time and end time
    startTime = moment(startTime);
    endTime = moment(endTime);
    var ms = moment(endTime, "DD/MM/YYYY HH:mm:ss").diff(
      moment(startTime, "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);
    return Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
  }

  const fetchTicket = async () => {
    console.log("Fetchticket?");
    const api = axios.create({
      baseURL: `${apiOrigin}/api/`,
    });
    TicketService.init(api);

    await TicketService.getTicketByToken(id).then((res) => {
      setTicket(res);
      setIsLoading(false);
    });
  };
  React.useEffect(() => {
    fetchTicket();
  }, []);

  React.useEffect(() => {
    if (ticket && ticket.start_time && !ticket.end_time) {
      const intervalRef = setInterval(function () {
        var now = moment();
        var then = moment(ticket.start_time);
        updateTime(now, then);
      }, 1000);
      return () => {
        clearInterval(intervalRef);
      };
    }
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={8} align="center">
        <Paper elevation={5} className={classes.root}>
          {/* <Card className={classes.root} variant="outlined"> */}

          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="h2">
              Ticket Details
            </Typography> */}

            <CardHeader
              action={
                <IconButton aria-label="settings">
                  <RefreshIcon
                    onClick={() => {
                      fetchTicket();
                    }}
                  />
                </IconButton>
              }
              title="Ticket Detail"
              subheader={ticket.token}
            />

            <img src={car} alt="Home header" width="70%" />
            <List>
              {/* <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ConfirmationNumberSharpIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Ticket Number"
                  secondary={ticket.token}
                />
                <RefreshIcon
                  style={{ float: "right" }}
                  onClick={() => {
                    fetchTicket();
                  }}
                />
              </ListItem> */}
              <ListItem>
                <ListItemText primary={ticket.name} secondary="Name" />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary={ticket.phone} secondary="Phone Number" />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText
                  primary={ticket.vehicle_type + " (" + ticket.color + ")"}
                  secondary="Vehicle Type (color)"
                />
                <ListItemText primary={ticket.plate} secondary="Plate Number" />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText
                  primary={
                    <Chip
                      label={String(ticket.payment_status).toUpperCase()}
                      style={{
                        backgroundColor:
                          ticket.payment_status === "paid" ? "green" : "red",
                        color: "white",
                      }}
                    />
                  }
                  secondary="Payment Status"
                />
                <ListItemText
                  primary={
                    <Chip
                      label={String(ticket.vehicle_status).toUpperCase()}
                      variant="outlined"
                      color={
                        ticket.vehicle_status === "retrieved"
                          ? "primary"
                          : "secondary"
                      }
                    />
                  }
                  secondary="Vehicle Status"
                  style={{ marginLeft: "10%" }}
                />
              </ListItem>
              <Divider component="li" />
              {ticket.vehicle_status === "parked" && (
                <ListItem>
                  <ListItemText
                    primary={moment(ticket.start_time).format(
                      "MM/DD/YYYY HH:mm:ss"
                    )}
                    secondary="Parked Time"
                  />
                  <ListItemText primary={parkTime} secondary="Duration" />
                </ListItem>
              )}
              {ticket.vehicle_status === "retrieved" && (
                <ListItem>
                  <ListItemText
                    primary={moment(ticket.end_time).format(
                      "MM/DD/YYYY HH:mm:ss"
                    )}
                    secondary="Retrieved Time"
                  />
                  <ListItemText
                    primary={getDuration(ticket.start_time, ticket.end_time)}
                    secondary="Duration"
                  />
                  <ListItemText
                    primary={"$" + ticket.total_amount.toFixed(2)}
                    secondary="Amount"
                  />
                </ListItem>
              )}{" "}
            </List>
          </CardContent>

          <CardActions>
            {ticket.payment_status === "unpaid" &&
              ticket.vehicle_status === "retrieved" && (
                <Checkout ticket={ticket} />
              )}
            {/* <Button color="primary" variant="contained" fullWidth>
              Make Payment
            </Button> */}
          </CardActions>
        </Paper>
      </GridItem>
    </GridContainer>
  );
}

export default TicketDetail;
