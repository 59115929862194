import React from "react";

// import styles from "assets/jss/material-kit-react/views/landingPage.js";
// import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import { Box, Button, Divider } from "@material-ui/core";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import HomeScreenWithoutLogin from "components/HomeScreenWithoutLogin";
import car from "../assets/img/car.svg";
import parkIcon from "../assets/img/svg/park_icon.svg";
import retriveIcon from "../assets/img/svg/retrive_icon.svg";
import statusIcon from "../assets/img/svg/vehicle_status_icon.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import UserService from "../services/UserService";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import CheckIcon from "@material-ui/icons/Check";
import history from "utils/history";
import { Context } from "Store";
import types from "Reducer/types";
import useAPI from "useAPI";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useStripe } from "@stripe/react-stripe-js";
import RefreshIcon from "@material-ui/icons/Refresh";
import Locations from "./Components/Locations";
import ManageMembership from "./Components/ManageMembershipButton";
import NotifyAttendant from "./Components/NotifyAttendantButton";
import VehicleStatus from "./Components/VehicleStatusButton";
import SubscriptionService from "../services/SubscriptionService";
import Loading from "components/Loading";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
    height: 100,
    width: "100%",
    cursor: "pointer",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    alignItems: "center",
  },
  cover: {
    width: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  coverParked: {
    width: 120,
    display: "flex",
    paddingTop: 20,
    justifyContent: "center",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cardText: {
    font: "normal normal 900 22px/30px Lato",
    letterSpacing: "0px",
    textAlign: "left",
    opacity: "1",
  },
  smallCardText: {
    font: "normal normal 900 22px/30px Lato",
    letterSpacing: "0px",
    textAlign: "left",
    opacity: "1",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
export default function Home() {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(Context);
  const api = useAPI();
  const {
    parked,
    parking,
    parkingSession,
    retrieving,
    discountamount = {},
    hasSubscriptionBooking,
    paymentMethod,
    subscribed,
    vehicles,
    plan,
  } = state;
  const [parkTime, setParkTime] = React.useState("");
  const [confirm, setConfirm] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [discountModel, setDiscountModel] = React.useState(false);
  const [confirmAmountModel, setConfirmAmountModel] = React.useState(false);
  const [values, setValues] = React.useState({});
  const [saving, setSaving] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [couponErrorOpen, setcouponErrorOpen] = React.useState(false);
  const [couponErrorMsg, setcouponErrorMsg] = React.useState("");
  const stripe = useStripe();
  const [amountSummary, setAmountSummary] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [redirected, setRedirected] = React.useState(false);

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep + 1 === steps.length) {
      console.log("HERE?", parkingSession);
      await api
        .get("/parking-session-amount/" + parkingSession.id)
        .then((res) => {
          setAmountSummary(res.data);
          setTimeout(() => {
            setOpenDialog(false);
            setConfirmAmountModel(true);
          }, 500);
        });
    }
  };

  function getSteps() {
    return [
      "Doors, trunk, tailgate are completly opened",
      "Corousel is not in motion",
    ];
  }

  const handleCode = async () => {
    if (code === "") {
      setCodeError("error");
      return;
    }
    await api
      .post("/apply-user-discount", {
        session: state.parkingSession,
        code,
      })
      .then((res) => {
        console.log("RES", res);
        if (res.data.err) {
          setcouponErrorMsg(res.data.message);
          setcouponErrorOpen(true);
        } else {
          setDiscountModel(false);
          let discount_amount = res.data.discountAmount;
          if (res.data.discountAmount >= res.data.totalAmount)
            discount_amount = res.data.totalAmount;
          let charged_amount = res.data.chargedAmount;
          let discountAmount = {
            total_amount: res.data.totalAmount,
            discount_amount,
            charged_amount,
          };
          dispatch({
            type: types.SET_DISCOUNT_AMOUNT,
            payload: discountAmount,
          });
        }
      });
  };
  const handleClose = async (tnc) => {
    setSaving(true);
    setValues({ ...values, is_new: 0, is_agree: tnc ? 1 : 0 });
    await UserService.updateUser({
      ...values,
      is_new: 0,
      is_agree: tnc ? 1 : 0,
    });
    setOpen(false);
    setSaving(false);
  };
  let activeColor = "#008BE8";
  let disabledColor = "#394253";
  let successColor = "#2E933C";
  let mutedColor = "#CACACA";

  let mainColor = activeColor;
  if (parked) {
    mainColor = disabledColor;
  }

  const fetchUser = async () => {
    await api.get("user").then((res) => {
      let sysUser = res.data;
      Object.keys(sysUser).forEach((key) => {
        sysUser[key] = sysUser[key] ? sysUser[key] : "";
      });
      console.log("cards", res.data);
      if (res.data.cards.length > 0) {
        dispatch({
          type: types.SET_PAYMENT_METHOD,
          payload: true,
        });
      } else {
        dispatch({
          type: types.SET_PAYMENT_METHOD,
          payload: false,
        });
      }
      if (sysUser.phone === "" || sysUser.name === "") {
        setRedirected(true);
        history.push({
          pathname: "/profile",
          state: { isRedirectFromHome: true },
        });
      }
      setValues({ ...sysUser });

      setOpen(res.data.is_new === 1 ? true : false);
      fetchCurrentBooking();
    });
  };
  const fetchCurrentBooking = async () => {
    SubscriptionService.init(api);
    await SubscriptionService.getSubscriptionBooking().then((res) => {
      if (res) {
        if (res.payment_status === "paid") {
          dispatch({
            type: types.SET_SUBSCRIBED,
            payload: true,
          });
          dispatch({
            type: types.SET_PLAN,
            payload: res.plan,
          });
        } else {
          dispatch({
            type: types.SET_SUBSCRIBED,
            payload: false,
          });
        }
        console.log("reservereservereservereserve", res);
        if (!res.plan) {
          history.push({
            pathname: "/reserve",
            state: { isRedirectFromHome: true },
          });
        } else if (!paymentMethod) {
          history.push({
            pathname: "/payment-card/new",
            state: { isRedirectFromPlan: true },
          });
        } else if (!subscribed) {
          history.push({ pathname: "/confirm-reserve" });
        }
      } else {
        history.push({
          pathname: "/vehicles/new",
          state: { isRedirectFromHome: true },
        });
      }
    });
    setIsLoading(false);
  };
  const updateTime = () => {
    var now = moment();
    var then = moment(parkingSession.start_time);
    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
      moment(then, "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);
    setParkTime(Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss"));
  };
  React.useEffect(() => {
    if (parked && parkingSession) {
      const intervalRef = setInterval(function () {
        updateTime();
      }, 1000);
      return () => {
        clearInterval(intervalRef);
      };
    }
  });

  React.useEffect(() => {
    UserService.init(api);

    fetchUser();
    // following code needs to be under api resonse that checks if organization is type of subscription booking
    dispatch({
      type: types.SET_SUBSCRIPTION_BOOKING,
      payload: true,
    });
    // if (!redirected) {
    //   setTimeout(() => {
    //     fetchCurrentBooking();
    //   }, 1000);
    // }
  }, [api]);

  React.useEffect(() => {
    dispatch({
      type: types.SET_DISCOUNT_AMOUNT,
      payload: {},
    });
    api
      .get("current-session")
      .then((res) => {
        dispatch({
          type: types.SET_CURRENT_PARKING_SESSION,
          payload: res.data,
        });
        if (res.data) {
          dispatch({
            type: types.SET_PARKED,
            payload: true,
          });
        } else {
          dispatch({
            type: types.SET_PARKED,
            payload: false,
          });
        }
      })
      .catch((e) => console.error(e));
    api
      .get("notifications-count")
      .then((res) => {
        dispatch({
          type: types.SET_NOTIFICATIONS,
          payload: res.data,
        });
      })
      .catch((e) => console.error(e));
  }, [parked]);

  const handlePark = async () => {
    if (!values.is_agree) {
      setOpen(true);
      return;
    }
    if (!localStorage.userLocationId) {
      return;
    }
    if (!parked && !parking) {
      history.push("/park/vehicle/select");
    }
    // setParked(true);
    // dispatch({ type: types.SET_PARKED, payload: true });
  };

  const handleRefresh = async () => {
    console.log("Refresh?");
    dispatch({
      type: types.SET_DISCOUNT_AMOUNT,
      payload: {},
    });
    api
      .get("current-session")
      .then((res) => {
        dispatch({
          type: types.SET_CURRENT_PARKING_SESSION,
          payload: res.data,
        });
        if (res.data) {
          dispatch({
            type: types.SET_PARKED,
            payload: true,
          });
        } else {
          dispatch({
            type: types.SET_PARKED,
            payload: false,
          });
        }
      })
      .catch((e) => console.error(e));
  };
  const handleCloseDialog = () => {
    setConfirmAmountModel(false);
    handleRetrive();
  };
  const handleRetrive = async () => {
    if (confirm) {
      dispatch({ type: types.SET_RETRIEVING, payload: true });
      api
        .post("retrive", {
          sessionId: parkingSession.id,
        })
        .then((res) => {
          console.log("Responseeee", res);
          if (res.data.success || res.data.payment.success) {
            api
              .get("user")
              .then((res) => {
                let sysUser = res.data;
                Object.keys(sysUser).forEach((key) => {
                  sysUser[key] = sysUser[key] ? sysUser[key] : "";
                });
                dispatch({ type: types.SET_USER, payload: sysUser });
                dispatch({ type: types.SET_PARKING, payload: false });
                dispatch({ type: types.SET_PARKED, payload: false });
                dispatch({ type: types.SET_RETRIEVING, payload: false });
                dispatch({
                  type: types.SET_DISCOUNT_AMOUNT,
                  payload: {},
                });
              })
              .catch((e) => {
                console.error(e);
                dispatch({ type: types.SET_PARKING, payload: false });
                dispatch({ type: types.SET_PARKED, payload: false });
                dispatch({ type: types.SET_RETRIEVING, payload: false });
                dispatch({
                  type: types.SET_DISCOUNT_AMOUNT,
                  payload: {},
                });
              });
          } else {
            stripe
              .handleCardAction(res.data.payment.payment_intent_client_secret)
              .then(function (result) {
                if (result.error) {
                  setErrorMessage(result.error.message);
                  setFailed(true);
                } else {
                  setFailed(false);
                  console.log("Handled?");
                  // The card action has been handled
                  // The PaymentIntent can be confirmed again on the server
                  api
                    .post("retrive", {
                      sessionId: parkingSession.id,
                    })
                    .then((resp) => {
                      if (resp.data.success || resp.data.payment.success) {
                        api
                          .get("user")
                          .then((resp) => {
                            let sysUser = resp.data;
                            Object.keys(sysUser).forEach((key) => {
                              sysUser[key] = sysUser[key] ? sysUser[key] : "";
                            });
                            dispatch({
                              type: types.SET_USER,
                              payload: sysUser,
                            });
                          })
                          .catch((e) => console.error(e));
                        setTimeout(() => {
                          dispatch({ type: types.SET_PARKING, payload: false });
                          dispatch({ type: types.SET_PARKED, payload: false });
                          dispatch({
                            type: types.SET_RETRIEVING,
                            payload: false,
                          });
                          dispatch({
                            type: types.SET_DISCOUNT_AMOUNT,
                            payload: {},
                          });
                        }, 1);
                      }
                    });
                }
              })
              .catch((e) => {
                console.log(e);
                dispatch({ type: types.SET_RETRIEVING, payload: false });
              });
          }
        })
        .catch((e) => {
          console.log(e);
          setConfirm(false);
          dispatch({ type: types.SET_RETRIEVING, payload: false });
        });
    } else {
      setOpenDialog(true);
      if (!openDialog) {
        setConfirm(true);
        dispatch({ type: types.SET_RETRIEVING, payload: false });
      }
    }
  };

  const getRetriveColor = () => {
    if (failed) {
      return "red";
    }
    if (confirm && parked) {
      return successColor;
    } else if (parked) {
      return activeColor;
    }
    return mutedColor;
  };

  const getSubscriptionBookingColor = () => {
    if (
      hasSubscriptionBooking &&
      vehicles.length > 0 &&
      paymentMethod &&
      subscribed
    ) {
      return activeColor;
    }
    return mutedColor;
  };

  const getDiscountButtonColor = () => {
    if (Object.keys(discountamount).length) {
      return successColor;
    }
  };
  const getRetriveButtonColor = () => {
    if (failed) {
      return "red";
    }
    if (confirm && parked) {
      return successColor;
    } else if (parked) {
      return "#FC9F0D";
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <AuthenticatedContainer unAuthChildren={<HomeScreenWithoutLogin />}>
      <>
        <GridItem xs={12} sm={12} md={8} align="center">
          <Box>
            <Typography
              component="h5"
              variant="h5"
              style={{ color: "black", padding: 10 }}
            >
              What would you like to do?
            </Typography>
          </Box>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} align="center">
          <Box p={5}>
            <img src={car} alt="Home header" width="100%" />
          </Box>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={8} align="center">
          <RouterNavLink to="/park">
            <Button color="primary" size="lg">
              <LocalParkingRoundedIcon className={classes.icons} /> Park
            </Button>
          </RouterNavLink>
        </GridItem> */}
        {hasSubscriptionBooking && (
          <>
            <ManageMembership color={getSubscriptionBookingColor} />
            <VehicleStatus color={getSubscriptionBookingColor} />
            <NotifyAttendant color={getSubscriptionBookingColor} />
          </>
        )}
        {!hasSubscriptionBooking && (
          <>
            <Locations />
            <GridItem xs={12} sm={12} md={8} align="center">
              <Box>
                <Card
                  className={classes.root}
                  elevation={6}
                  style={{ height: "100%" }}
                  onClick={handlePark}
                >
                  <CardMedia
                    className={classes.cover}
                    style={{
                      backgroundColor:
                        parked || parking ? mutedColor : mainColor,
                    }}
                    title="Park"
                  >
                    <img
                      src={parkIcon}
                      alt="Park"
                      style={parked ? { height: "40%" } : {}}
                    />
                  </CardMedia>
                  <CardContent className={classes.content}>
                    <h4
                      className={
                        parked ? classes.smallCardText : classes.cardText
                      }
                      style={{ color: mainColor }}
                    >
                      PARK
                    </h4>
                  </CardContent>
                </Card>
              </Box>
            </GridItem>
            <GridItem xs={12} sm={12} md={8} align="center">
              <Box style={{}}>
                <Card className={classes.root} style={{ height: "100%" }}>
                  <CardMedia
                    className={!parked ? classes.cover : classes.coverParked}
                    style={{
                      backgroundColor: getRetriveColor(),
                    }}
                    title="retrieve"
                  >
                    <img
                      src={retriveIcon}
                      alt="Retrive"
                      style={!parked ? { height: "40%" } : { height: "30px" }}
                    />
                  </CardMedia>
                  <CardContent
                    className={classes.content}
                    style={
                      parked
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }
                        : {}
                    }
                  >
                    <GridItem style={{ padding: "0px" }} align="left">
                      <h4
                        style={{
                          color: parked ? activeColor : mutedColor,
                          font: !parked
                            ? "normal normal 900 22px/30px Lato"
                            : "normal normal 900 20px/25px Lato",
                        }}
                      >
                        RETRIEVE
                        {(parked && (
                          <RefreshIcon
                            style={{ float: "right" }}
                            onClick={() => {
                              handleRefresh();
                            }}
                          />
                        )) ||
                          null}
                      </h4>
                    </GridItem>
                    {(parked && parkingSession && (
                      <>
                        <Divider />
                        <Typography
                          variant="caption"
                          align="left"
                          style={{ paddingBottom: 10 }}
                        >
                          Parked Vehicle: {parkingSession.vehicle.make} -{" "}
                          {parkingSession.vehicle.model}
                        </Typography>
                        <Typography
                          variant="caption"
                          align="left"
                          style={{ paddingBottom: 10 }}
                        >
                          Parked at:{" "}
                          {moment(parkingSession.start_time).format(
                            "MM/DD/YYYY HH:mm:ss"
                          )}
                        </Typography>
                        <Typography
                          variant="caption"
                          align="left"
                          style={{ paddingBottom: 10 }}
                        >
                          Park timer: {parkTime}
                        </Typography>
                        <Typography
                          variant="caption"
                          align="left"
                          style={{ paddingBottom: 10 }}
                        >
                          Your vehicle will be available momentarily. Before you
                          depart, please remember to:
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold", textAlign: "left" }}
                        >
                          {`+ Be patient and be respectful towards the parking attendant and request for help if required.`}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          {`+ Please wait until the garage door is completely opened.`}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          {`+ Be cautious when removing your vehicle from the parking platform.`}
                        </Typography>

                        {(Object.keys(discountamount).length && (
                          <>
                            <Typography
                              variant="caption"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "inherit",
                              }}
                            >
                              {"Total Amount: - "}
                              {discountamount.total_amount}
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "inherit",
                              }}
                            >
                              {"Discount Amount: - "}
                              {discountamount.discount_amount}$
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "inherit",
                              }}
                            >
                              Payable Amount: - {discountamount.charged_amount}$
                            </Typography>
                          </>
                        )) ||
                          null}
                        <GridItem>
                          <Dialog
                            open={openDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"Safety Check"}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                <Stepper
                                  activeStep={activeStep}
                                  orientation="vertical"
                                >
                                  {steps.map((label) => (
                                    <Step key={label}>
                                      <StepLabel>{label}</StepLabel>
                                      <StepContent>
                                        <div
                                          className={classes.actionsContainer}
                                        >
                                          <div>
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={handleNext}
                                              className={classes.button}
                                            >
                                              {activeStep === steps.length - 1
                                                ? "Finish"
                                                : "Next"}
                                            </Button>
                                          </div>
                                        </div>
                                      </StepContent>
                                    </Step>
                                  ))}
                                </Stepper>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              {activeStep === steps.length && (
                                <Paper
                                  square
                                  elevation={0}
                                  className={classes.resetContainer}
                                >
                                  <Typography>
                                    Thank you for safety check.
                                  </Typography>
                                </Paper>
                              )}
                              {saving && (
                                <CircularProgress
                                  size={14}
                                  className={classes.buttonProgress}
                                />
                              )}
                            </DialogActions>
                          </Dialog>
                        </GridItem>
                        <Button
                          variant="contained"
                          disabled={!!Object.keys(discountamount).length}
                          // fullWidth
                          style={{
                            color: activeColor,
                            marginTop: 20,
                            backgroundColor: getDiscountButtonColor(),
                          }}
                          onClick={() => {
                            setDiscountModel(true);
                          }}
                        >
                          {Object.keys(discountamount).length
                            ? "Promo Code Applied Successfully"
                            : "Do you have Promo Code?"}
                        </Button>

                        <Button
                          variant="contained"
                          fullWidth
                          disabled={retrieving}
                          size="large"
                          style={{
                            color: "white",
                            marginTop: 20,
                            backgroundColor: getRetriveButtonColor(),
                          }}
                          onClick={handleRetrive}
                          startIcon={
                            confirm ? <CheckIcon /> : <PriorityHighIcon />
                          }
                        >
                          {retrieving
                            ? "RETRIEVING PAYMENT"
                            : "CONFIRM DEPARTURE"}
                        </Button>
                        <Divider />
                        {(failed && (
                          <Typography
                            variant="caption"
                            align="left"
                            style={{
                              paddingBottom: 10,
                              marginTop: 20,
                              color: "red",
                            }}
                          >
                            {errorMessage}
                          </Typography>
                        )) ||
                          null}
                        <Typography
                          variant="caption"
                          align="left"
                          style={{
                            paddingBottom: 10,
                            marginTop: 20,
                            color: getRetriveButtonColor(),
                          }}
                        >
                          Departure confirmation will automatically occur in{" "}
                          <b>15 minutes</b>
                        </Typography>
                      </>
                    )) ||
                      null}
                  </CardContent>
                </Card>
              </Box>
            </GridItem>
            <GridItem xs={12} sm={12} md={8} align="center">
              <Box style={{}}>
                <Card className={classes.root} style={{ height: "100%" }}>
                  <CardMedia
                    className={classes.cover}
                    style={{
                      backgroundColor: !parked ? mutedColor : "#FC9F0D",
                    }}
                    title="Status"
                  >
                    <img
                      src={statusIcon}
                      alt="Status"
                      style={{ height: "40%" }}
                    />
                  </CardMedia>
                  <CardContent className={classes.content}>
                    <h4
                      className={
                        !parked ? classes.smallCardText : classes.cardText
                      }
                      style={{
                        color: !parked ? mutedColor : "#FC9F0D",
                        font: "normal normal 900 22px/30px Lato",
                      }}
                    >
                      VEHICLE STATUS
                    </h4>
                  </CardContent>
                </Card>
              </Box>
            </GridItem>
            <GridItem xs={12} sm={12} md={8} align="center">
              <Box>
                <h2 style={{ color: "transparent" }}> - </h2>
              </Box>
            </GridItem>
            <GridItem xs={12} sm={12} md={8} align="center">
              <Box mt={5}>
                <Snackbar
                  open={couponErrorOpen}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={() => {
                      setcouponErrorOpen(false);
                    }}
                    severity="error"
                  >
                    {couponErrorMsg}
                  </Alert>
                </Snackbar>
              </Box>
            </GridItem>
          </>
        )}
        {/* 
        <GridItem xs={12} sm={12} md={8} align="center">
          <Box>
            <img src={retriveButton} alt="Home header" width="100%" />
          </Box>
        </GridItem>

        <GridItem xs={12} sm={12} md={8} align="center">
          <Box>
            <img src={statusButton} alt="Home header" width="100%" />
          </Box>
        </GridItem> */}
      </>
    </AuthenticatedContainer>
  );
}
