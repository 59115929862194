const types = {
  SET_USER: "SET_USER",
  SET_USER_PIN: "SET_USER_PIN",
  SET_PARKING: "SET_PARKING",
  SET_PARKED: "SET_PARKED",
  SET_RETRIEVING: "SET_RETRIEVING",
  SET_VEHICLES: "SET_VEHICLES",
  SET_CURRENT_PARKING_SESSION: "SET_CURRENT_PARKING_SESSION",
  SET_DISCOUNT_AMOUNT: "SET_DISCOUNT_AMOUNT",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  SET_SUBSCRIPTION_BOOKING: "SET_SUBSCRIPTION_BOOKING",
  SET_PAYMENT_METHOD: "SET_PAYMENT_METHOD",
  SET_SUBSCRIBED: "SET_SUBSCRIBED",
  SET_TNC_CHANGE: "SET_TNC_CHANGE",
  SET_PLAN: "SET_PLAN",
};

export default types;
