import React from "react";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import VehicleService from "../services/VehicleService";

import useAPI from "useAPI";

const useStyles = makeStyles((theme) => ({
  ...styles,
  float: {
    margin: theme.spacing(1),
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  floatPin: {
    margin: theme.spacing(1),
    top: "auto",
    right: "auto",
    bottom: 30,
    left: 20,
    position: "fixed",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function Park() {
  const classes = useStyles();
  const api = useAPI();
  React.useEffect(() => {
    VehicleService.init(api);
  }, [api]);

  return (
    <AuthenticatedContainer>
      <>
        <GridItem xs={12} sm={12} md={8} align="center">
          <Typography variant="h4" color="secondary">
            Park Vehicle
          </Typography>
        </GridItem>

        <GridItem xs={12} sm={12} md={8} align="center">
          <List className={classes.root} subheader={<li />}>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader>{`Parking Location`}</ListSubheader>
                <ListItem key={`item-one`}>
                  <ListItemText
                    primary={`Location one, Code: 123345`}
                    style={{ color: "black" }}
                  />
                </ListItem>
              </ul>
            </li>
          </List>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} align="center">
          <List className={classes.root} subheader={<li />}>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader>{`Parking Instructions:`}</ListSubheader>
                <ListItem key={`item-one`}>
                  <ListItemText
                    primary={`Item One`}
                    style={{ color: "black" }}
                  />
                </ListItem>
                <ListItem key={`item-two`}>
                  <ListItemText
                    primary={`Item two`}
                    style={{ color: "black" }}
                  />
                </ListItem>
                <ListItem key={`item-Three`}>
                  <ListItemText
                    primary={`Item Three`}
                    style={{ color: "black" }}
                  />
                </ListItem>
              </ul>
            </li>
          </List>
        </GridItem>
      </>
    </AuthenticatedContainer>
  );
}

export default Park;
