import React from "react";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import LocationService from "../../services/LocationService";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import useAPI from "useAPI";

const useStyles = makeStyles((theme) => ({
  ...styles,
  float: {
    margin: theme.spacing(1),
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  floatPin: {
    margin: theme.spacing(1),
    top: "auto",
    right: "auto",
    bottom: 30,
    left: 20,
    position: "fixed",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  newButton: { marginTop: 20, color: "white" },
}));

export const usePosition = () => {
  const [position, setPosition] = React.useState({});
  const [error, setError] = React.useState(null);

  const onChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error) => {
    setError(error.message);
  };
  React.useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      console.log("Not Supported");
      setError("Geolocation is not supported");
      return;
    }
    let watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);
  return { ...position, error };
};

function distance(lat1, lon1, lat2, lon2) {
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

function Location() {
  const classes = useStyles();
  const api = useAPI();
  const { latitude, longitude } = usePosition();

  const [locationList, setLocationList] = React.useState([]);
  const [userLocation, setUserLocation] = React.useState(
    localStorage.userLocationId
  );

  const handleChange = (val) => {
    console.log("val", val);
    setUserLocation(val);
    localStorage.setItem("userLocationId", val);
  };

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      var ans = Math.min(); // infinity
      var nearestLocationObj = {};

      setLocationList(
        res.map((item) => {
          const obj = {
            id: item.id,
            name: item.name + " - " + item.zipcode,
            value: item.id,
            latitude: item.latitude,
            longitude: item.longitude,
          };
          var d = distance(latitude, longitude, item.latitude, item.longitude);

          if (d <= ans) {
            ans = d;
            nearestLocationObj = obj;
          }
          return obj;
        })
      );
      console.log(nearestLocationObj.id);
      setUserLocation(nearestLocationObj.id);
      localStorage.setItem("userLocationId", nearestLocationObj.id);
    });
  };

  React.useEffect(() => {
    LocationService.init(api);
    fetchLocationList();
  }, [latitude, longitude]);

  return (
    <GridItem xs={12} sm={12} md={8} align="center">
      <FormControl
        variant="outlined"
        className={classes.formControl}
        hidden={localStorage.scanned}
      >
        <InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
        <Select
          style={{ width: 300 }}
          label="Location"
          value={userLocation}
          required
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          // error={errors.color === "error"}
        >
          <MenuItem
            disabled
            value={""}
            classes={{
              root: classes.selectMenuItem,
            }}
          >
            Choose Location
          </MenuItem>

          {locationList &&
            locationList.map((location) => (
              <MenuItem
                value={`${location.id}`}
                key={`${location.id}`}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
              >
                {`${location.name}`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </GridItem>
  );
}

export default Location;
