import React from "react";

// import styles from "assets/jss/material-kit-react/views/landingPage.js";
// import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import { Button } from "@material-ui/core";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserService from "../../services/UserService";
import { makeStyles } from "@material-ui/core/styles";
import history from "utils/history";
import { Context } from "Store";
import types from "Reducer/types";
import useAPI from "useAPI";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TermsAndConditions from "../TermsAndConditions";
import AxiosService from "../../services/AxiosService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer.js";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
export default function TNCDialogBox() {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(Context);
  const { tncChange } = state;
  const api = useAPI();
  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [values, setValues] = React.useState({});
  const [saving, setSaving] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  const handleClose = async (tnc) => {
    setSaving(true);
    setValues({ ...values, is_new: 0, is_agree: tnc ? 1 : 0 });
    UserService.init(api);
    UserService.updateUser({
      ...values,
      is_new: 0,
      is_agree: tnc ? 1 : 0,
    }).then((res) => {
      dispatch({
        type: types.SET_TNC_CHANGE,
        payload: !tncChange,
      });
    });
    setOpen(false);
    setSaving(false);
  };

  const fetchUser = async () => {
    // UserService.init(api);
    const token = localStorage.getItem("apiToken");
    await AxiosService.setAxiosInterceptor(token);
    await api.get("user").then((res) => {
      let sysUser = res.data;
      Object.keys(sysUser).forEach((key) => {
        sysUser[key] = sysUser[key] ? sysUser[key] : "";
      });
      console.log("cards", res.data);
      if (res.data.cards.length > 0) {
        dispatch({
          type: types.SET_PAYMENT_METHOD,
          payload: true,
        });
      } else {
        dispatch({
          type: types.SET_PAYMENT_METHOD,
          payload: false,
        });
      }
      if (res.data.phone == "") {
        history.push({
          pathname: "/profile",
          state: { isRedirectFromHome: true },
        });
      }
      setValues({ ...sysUser });
      setOpenConfirmation(res.data.is_new === 1 ? true : false);
      setOpen(res.data.is_new === 1 ? true : false);
    });
  };
  React.useEffect(() => {
    UserService.init(api);
    fetchUser();
  }, [api]);

  return (
    <AuthenticatedContainer>
      <>
        <GridItem xs={12} sm={12} md={8} align="center">
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Terms and Conditions"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <TermsAndConditions />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(event) => {
                          setChecked(event.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label="I have read the Terms and Conditions and Privacy Policy"
                  />
                </GridItem>
                <GridItem item xs={12} sm={12} md={12}>
                  <div>
                    <Button
                      onClick={() => {
                        console.log("herer?");
                        handleClose(true);
                      }}
                      color="primary"
                      autoFocus
                      disabled={!checked}
                      variant="outlined"
                      className="float-right"
                    >
                      Agree
                    </Button>
                    {saving && (
                      <CircularProgress
                        size={14}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <Button
                    onClick={() => {
                      handleClose(false);
                      console.log("therer?");
                    }}
                    // color="primary"
                    disabled={!checked}
                    variant="outlined"
                    className="float-right"
                  >
                    Disagree
                  </Button>
                </GridItem>
              </GridContainer>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Registration Successful"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Thank you, user registration successfully completed, please
                complete your profile in the next section of the app.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenConfirmation(false);
                }}
                color="primary"
                variant="outlined"
                autoFocus
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </GridItem>
      </>
    </AuthenticatedContainer>
  );
}
