import React from "react";

import { Typography } from "@material-ui/core";
import GridItem from "./Grid/GridItem";

export default function UnAuthorizedScreen() {
  return (
    <GridItem xs={12} sm={12} md={8} align="center">
      <Typography variant="h2" color="primary">
        401 Unauthorized
      </Typography>
    </GridItem>
  );
}
