import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getVehicles = async () => {
  try {
    let cancel;
    const response = await api.get("/vehicles", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getVehicleMakes = async (search) => {
  try {
    let cancel;
    const response = await api.get("/vehicles/makes/" + search, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getVehicleById = async (id) => {
  try {
    let cancel;
    const response = await api.get("/vehicles/" + id, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getVehicleModel = async (id) => {
  try {
    let cancel;
    const response = await api.get("/vehicles/models/" + id, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getVehiclesList = async () => {
  try {
    let cancel;
    const response = await api.get("/vehicles", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storeVehicle = async (data) => {
  try {
    let cancel;
    const response = await api.put("/vehicles", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const VehicleService = {
  init,
  getVehiclesList,
  getVehicles,
  getVehicleById,
  storeVehicle,
  getVehicleMakes,
  getVehicleModel,
};

export default VehicleService;
