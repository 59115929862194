import React from "react";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import LocationService from "../../services/LocationService";

// import GridItem from "components/Grid/GridItem.js";
import { Box, Button, Divider } from "@material-ui/core";
import parkIcon from "../../assets/img/svg/park_icon.svg";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import history from "utils/history";
import { Context } from "Store";
import useAPI from "useAPI";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
    height: 100,
    width: "100%",
    cursor: "pointer",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    alignItems: "center",
  },
  cover: {
    width: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  coverParked: {
    width: 120,
    display: "flex",
    paddingTop: 20,
    justifyContent: "center",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cardText: {
    font: "normal normal 900 22px/30px Lato",
    letterSpacing: "0px",
    textAlign: "left",
    opacity: "1",
  },
  smallCardText: {
    font: "normal normal 900 22px/30px Lato",
    letterSpacing: "0px",
    textAlign: "left",
    opacity: "1",
  },
}));
function NotifyAttendant({ color = "#CACACA" }) {
  const classes = useStyles();
  const api = useAPI();
  const { state, dispatch } = React.useContext(Context);

  const {
    parked,
    parking,
    parkingSession,
    retrieving,
    discountamount = {},
    hasSubscriptionBooking,
    paymentMethod,
    subscribed,
    vehicles,
  } = state;

  const [values, setValues] = React.useState({});

  let activeColor = "#008BE8";
  let disabledColor = "#394253";
  let mutedColor = "#CACACA";

  let mainColor = activeColor;
  if (parked) {
    mainColor = disabledColor;
  }
  const getSubscriptionBookingColor = () => {
    if (
      hasSubscriptionBooking &&
      paymentMethod &&
      subscribed
      // vehicles.length > 0 &&
    ) {
      console.log("active", activeColor);
      return activeColor;
    }
    return mutedColor;
  };

  color = getSubscriptionBookingColor();
  const handleNotifyAttendant = async () => {
    if (color === activeColor) {
      history.push("/notify-attendant");
    }
  };

  return (
    <GridItem xs={12} sm={12} md={8} align="center">
      <Box>
        <Card
          className={classes.root}
          elevation={6}
          style={{ height: "100%" }}
          onClick={handleNotifyAttendant}
        >
          <CardMedia
            className={classes.cover}
            style={{
              backgroundColor: color,
              width: 105,
            }}
            title="Notify Attendant"
          >
            <img
              src={parkIcon}
              alt="Notify Attendant"
              style={parked ? { height: "40%" } : {}}
            />
          </CardMedia>
          <CardContent className={classes.content}>
            <h4
              className={parked ? classes.smallCardText : classes.cardText}
              style={{ color: color }}
            >
              NOTIFY ATTENDANT
            </h4>
          </CardContent>
        </Card>
      </Box>
    </GridItem>
  );
}
// NotifyAttendant.propTypes = {
//   color: PropTypes.string,
// };

NotifyAttendant.defaultProps = {
  color: "#CACACA",
};

export default NotifyAttendant;
