import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getCards = async () => {
  try {
    let cancel;
    const response = await api.get("/payment-cards", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCardById = async (id) => {
  try {
    let cancel;
    const response = await api.get("/payment-cards/" + id, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getUserDefaultCard = async () => {
  try {
    let cancel;
    const response = await api.get("/default-payment-card", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storeCard = async (data) => {
  console.log(data);
  try {
    let cancel;
    const response = await api.post("/payment-cards", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const setDefaultCard = async (data) => {
  try {
    let cancel;
    const response = await api.post(`/payment-cards/${data.id}`, data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteCard = async (data) => {
  try {
    let cancel;
    const response = await api.delete(`/payment-cards/${data.id}`, data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const CardService = {
  init,
  getCards,
  storeCard,
  getCardById,
  getUserDefaultCard,
  setDefaultCard,
  deleteCard,
};

export default CardService;
