import React from "react";
import { Context } from "Store";
import types from "Reducer/types";
// core components
import GridItem from "components/Grid/GridItem.js";
import SubscriptionService from "../services/SubscriptionService";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import car from "../assets/img/car.svg";
import sudan1 from "../assets/img/sedan_parking1.png";
import electric1 from "../assets/img/electric1.png";
import sudan2 from "../assets/img/sedan_parking2.png";
import suv1 from "../assets/img/suv_parking1.webp";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardHeader from "@material-ui/core/CardHeader";
import useAPI from "useAPI";
import { Box, Typography, Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import moment from "moment";
import history from "utils/history";
import Snackbar from "@material-ui/core/Snackbar";
// import Alert from "@material-ui/lab/Alert";
import MuiAlert from "@material-ui/lab/Alert";
import HomeIcon from "@material-ui/icons/Home";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  ...styles,

  inputLabel: {
    color: "#008BE8",
    font: "normal normal bold 16px/19px Lato",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  sidetext: {
    color: "#324152",
    float: "left",
    paddingLeft: "10px",
  },
  root: {
    width: "100%",
    // maxWidth: 460,
    position: "relative",
    overflow: "auto",
    // maxHeight: 600,
    paddingBottom: "5%",
    marginBottom: "5%",
  },
  media: {
    height: 140,
  },
  badge: {
    top: 20,
    right: 20,
  },
});

function Plan() {
  const classes = useStyles();
  const [plans, setPlans] = React.useState([]);
  const api = useAPI();
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [plan, setPlan] = React.useState(null);
  const planImages = new Map();
  const [userPlan, setUserPlan] = React.useState(null);

  const { state, dispatch } = React.useContext(Context);
  const { hasSubscriptionBooking, paymentMethod } = state;

  planImages.set("Electric Car", electric1);
  planImages.set("sedan", sudan1);
  planImages.set("Electric SUV", suv1);
  planImages.set("Non Electric SUV", sudan2);
  planImages.set("Non Electric SUV", sudan2);

  const handleReserve = async (plan) => {
    setPlan(plan);
    dispatch({
      type: types.SET_PLAN,
      payload: plan,
    });
    if (!paymentMethod) {
      history.push({
        pathname: "/payment-card/new",
        state: { isRedirectFromPlan: true },
      });
    } else {
      // redirect on confirmation screen
      history.push({
        pathname: "/confirm-reserve",
        state: { isRedirectFromPlan: true },
      });
    }
    // setOpen(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handBacktoHome = () => {
    history.push({ pathname: "/" });
  };

  const handleClose = async () => {
    setSaving(true);
    await SubscriptionService.createPlan(plan.id).then((res) => {
      setOpenSnackbar(true);
      console.log("RESPONSE", res);
      if (hasSubscriptionBooking) {
        dispatch({
          type: types.SET_SUBSCRIBED,
          payload: true,
        });
      }
      setRefresh(!refresh);
    });
    setOpen(false);
    setSaving(false);
  };

  const fetchPlans = async () => {
    SubscriptionService.init(api);

    await SubscriptionService.getPlans().then((res) => {
      setUserPlan(res.userPlan);
      setPlans(res.plans);
    });
  };

  const fetchCurrentBooking = async () => {
    SubscriptionService.init(api);
    await SubscriptionService.getSubscriptionBooking().then((res) => {
      console.log("rrrrresss", res);
      if (res) {
        if (res.payment_status === "paid") {
          dispatch({
            type: types.SET_SUBSCRIBED,
            payload: true,
          });
          dispatch({
            type: types.SET_PLAN,
            payload: res.plan,
          });
        } else {
          dispatch({
            type: types.SET_SUBSCRIBED,
            payload: false,
          });
        }
        console.log("paymentcard", paymentMethod);
        // if (!paymentMethod) {
        //   history.push({ pathname: "/payment-card/new" });
        // }
      } else {
        history.push({
          pathname: "/vehicles/new",
          state: { isRedirectFromHome: true },
        });
      }
    });
  };

  React.useEffect(() => {
    fetchCurrentBooking();
    fetchPlans();
  }, [refresh]);

  const getBadge = (plan) => {
    if (userPlan && userPlan.plan_id === plan.id) {
      return "Subscribed";
    }
    if (plan.offer) {
      return (
        <span>
          <LocalOfferIcon fontSize="small" /> Offer{" "}
        </span>
      );
    }
    return 0;
  };

  return (
    <GridContainer justify="center" style={{ margin: 10 }}>
      <GridItem xs={12} sm={12} md={12} align="center">
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure to want to subscribe ${
                plan ? plan.plan_name : ""
              } plan?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleClose();
              }}
              color="primary"
              autoFocus
            >
              Continue
            </Button>
            {saving && (
              <CircularProgress size={14} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
        <GridItem
          xs={12}
          sm={12}
          md={8}
          align="center"
          style={{ paddingBottom: 15 }}
        >
          <Typography variant="h5" color="primary">
            {userPlan && userPlan.plan_id
              ? "Subscription"
              : "Select Subscription"}
          </Typography>
        </GridItem>
        {(userPlan && userPlan.plan_id && (
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={handBacktoHome}
            startIcon={<HomeIcon />}
          >
            Back to Home
          </Button>
        )) ||
          null}
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {plans.map((plan) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={{ margin: 10 }}
              key={plans.indexOf(plan)}
            >
              <Badge
                classes={{ anchorOriginTopRightRectangle: classes.badge }}
                color={plan.offer ? "secondary" : "primary"}
                badgeContent={getBadge(plan)}
              >
                <Card
                  style={{
                    backgroundColor:
                      userPlan && userPlan.plan_id === plan.id
                        ? "#4daf58"
                        : plan.offer === 1
                        ? "#ead4a3"
                        : "white",
                    minHeight: "50vh",
                  }}
                >
                  <img
                    src={
                      planImages.get(plan.vehicle_type)
                        ? planImages.get(plan.vehicle_type)
                        : car
                    }
                    style={{
                      minHeight: "170px",
                    }}
                    alt="Home header"
                    width="70%"
                  />
                  <CardHeader
                    title={plan.plan_name}
                    style={{ paddingBottom: "0px", paddingTop: "0px" }}
                  />

                  <CardContent
                    style={{ paddingBottom: "0px", paddingTop: "0px" }}
                  >
                    <Box fontWeight="fontWeightBold" m={1} align="left">
                      {`${plan.duration} Month(s)`}
                    </Box>
                    <Box fontWeight="fontWeightLight" m={1} align="left">
                      {`${plan.notes ? plan.notes : ""}`}
                    </Box>
                    <Box fontWeight="fontWeightBold" m={1} align="left">
                      {`$${plan.amount / plan.duration}/month`}
                      {/* {`[$${plan.amount}/${
                        plan.duration === 3 ? "QUARTER" : "YEAR"
                      }]`} */}
                    </Box>
                    {userPlan && userPlan.plan_id === plan.id && (
                      <>
                        <Box fontWeight="fontWeightBold" m={1} align="left">
                          Current Plan :
                          {` ${moment(userPlan.start_date).format(
                            "MM/DD/YYYY "
                          )} to ${moment(userPlan.end_date).format(
                            "MM/DD/YYYY "
                          )}`}
                        </Box>
                        <Box
                          fontWeight="fontWeightBold"
                          m={1}
                          align="left"
                          style={{ color: "#800000" }}
                        >
                          {`Next Billing Date : ${moment(
                            userPlan.next_billing_date
                          ).format("MM/DD/YYYY ")}`}
                        </Box>
                      </>
                    )}
                    {!(userPlan && userPlan.plan_id === plan.id) && (
                      <Typography variant="h5" gutterBottom>
                        <Button
                          onClick={() => handleReserve(plan)}
                          // disabled={userPlan ? true : false}
                          color="primary"
                          variant="contained"
                          fullWidth
                          style={{
                            marginTop: plan.notes ? 20 : 20,
                            color: "white",
                          }}
                        >
                          {userPlan ? "Upgrade Plan" : "Reserve Now"}
                        </Button>
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Badge>
            </Grid>
          ))}
        </Grid>
        <div>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="success" onClose={handleCloseSnackbar}>
              Plan Subscribed Successfully
            </Alert>
          </Snackbar>
        </div>
      </GridItem>
    </GridContainer>
  );
}

export default Plan;
