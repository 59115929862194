import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;
// const IN_USE = "The Carousel is currently in use.";

const getCarouselSlot = async (data) => {
  try {
    let cancel;
    const response = await api.post(`/carousels/${data.carouselId}/pin`, data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCarouselForLocation = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/carousels/${id}/list-by-location`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCarouselByToken = async (token) => {
  try {
    let cancel;
    const response = await api.get(`/carousels/${token}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const CarouselService = {
  init,
  getCarouselSlot,
  getCarouselForLocation,
  getCarouselByToken,
};

export default CarouselService;
