import { useRef, useEffect } from "react";
import axios from "axios";
import { getConfig } from "config";

import { useAuth0 } from "@auth0/auth0-react";

export default () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin = "http://localhost:3001" } = getConfig();
  const api = useRef(
    axios.create({
      baseURL: `${apiOrigin}/api/`,
    })
  );
  useEffect(() => {
    const currentAPI = api.current;
    const requestInterceptorId = currentAPI.interceptors.request.use(
      async (config) => {
        const token = await getAccessTokenSilently();
        config.headers.authorization = `Bearer ${token}`;
        config.cancelToken = axios.CancelToken.source().token;
        if (localStorage.getItem("apiToken") === token) {
          return config;
        }
        try {
          localStorage.setItem("apiToken", token);
          await axios.get(`${apiOrigin}/api/status`, {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
        } catch (e) {
          console.log(e);
        }
        return config;
      }
    );
    return () => {
      currentAPI.interceptors.request.eject(requestInterceptorId);
    };
  });
  return api.current;
};
