const defaultState = {
  user: {},
  parked: false,
  retrieving: false,
  parkingSession: null,
  vehicles: [],
  error: null,
  notificationCount: 0,
  discountamount: {},
  paymentMethod: false,
  tncChange: false,
  subscribed: false,
  plan: null,
};

export default defaultState;
