import React from "react";

import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { Typography } from "@material-ui/core";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getConfig } from "../../config";

import CheckoutForm from "./CheckoutForm";

const config = getConfig();
const stripePromise = loadStripe(config.stripePublishKey);

const useStyles = makeStyles((theme) => ({
  // ...styles,
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
    color: "#324152",
    marginTop: 20,
  },
  tabStyle: { color: "#324152", marginTop: 20 },
}));

function PaymentButton() {
  const classes = useStyles();

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Typography variant="h5" style={{ color: "#324152" }}>
          Apple Pay / Google Pay
        </Typography>
      </GridItem>

      <GridItem xs={12} sm={12} md={8} align="center">
        <div className={classes.root}>
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </div>
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default PaymentButton;
