import Axios from "axios";
import { getConfig } from "config";

const setAxiosInterceptor = async (token) => {
  var isAbsoluteURLRegex = /^(?:\w+:)\/\//;
  console.log("setAxiosInterceptor");
  Axios.interceptors.request.use(
    async (config) => {
      // eslint-disable-next-line prefer-const
      const { apiOrigin = "http://localhost:3001" } = getConfig();
      config.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      // console.log(request.url)
      if (!isAbsoluteURLRegex.test(config.url)) {
        config.url = apiOrigin + "/api" + config.url;
      }
      console.log(config);
      return config;
    },
    (error) => error
  );
};

const AxiosService = {
  setAxiosInterceptor,
};

export default AxiosService;
