/* eslint-disable no-unused-vars */
import React from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import { Box, Typography, Button } from "@material-ui/core";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useParams } from "react-router";
import VehicleService from "services/VehicleService";
import useAPI from "useAPI";
import { useLocation } from "react-router-dom";
import history from "utils/history";
import { Context } from "Store";
import CardService from "services/CardService";
import CarouselService from "services/CarouselService";
import types from "Reducer/types";

const useStyles = makeStyles((theme) => ({
  ...styles,
  customTextField: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px",
    flexBasis: "auto",
  },
  inputLabel: {
    color: "#008BE8",
    font: "normal normal bold 16px/19px Lato",
  },
  float: {
    margin: theme.spacing(1),
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  floatPin: {
    margin: theme.spacing(1),
    top: "auto",
    right: "auto",
    bottom: 30,
    left: 20,
    position: "fixed",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function CarouselDetail() {
  const classes = useStyles();
  const [saving, setSaving] = React.useState(false);
  const [vehicle, setVehicle] = React.useState({});
  const [carousels, setCarousels] = React.useState([]);
  const { state, dispatch } = React.useContext(Context);
  const [hasDefaultCard, setHasDefaultCard] = React.useState(false);
  const [generatingPin, setGeneratingPin] = React.useState(true);
  const [carouselMessage, setCarouselMessage] = React.useState("");
  const [defaultCard, setDefaultCard] = React.useState({});
  const [slot, setSlot] = React.useState({});
  const [openCarouselError, setopenCarouselError] = React.useState(false);
  const [carouselErrorMessage] = React.useState([]);
  const steps = getSteps();

  let { id } = useParams();
  const api = useAPI();

  function getSteps() {
    return [
      "Press green button to start parking process",
      "Wait until garage door is being opened",
      "Provide your pin to parking attendant",
      "Be patient while parking",
    ];
  }

  React.useEffect(() => {
    if (state.parked) {
      history.replace("/");
    }
    CardService.init(api);
    CarouselService.init(api);
    CardService.getUserDefaultCard().then((res) => {
      if (res) {
        setHasDefaultCard(true);
        setDefaultCard(res);
      } else {
        history.push("/park/vehicle/" + id + "/card/new");
      }
    });
    const selectedCarousel = JSON.parse(localStorage.selectedCarousel || {});
    if (selectedCarousel && selectedCarousel.nick_name) {
      setCarouselMessage(`Please Drive to ${selectedCarousel.nick_name}`);
    } else if (selectedCarousel && selectedCarousel.id) {
      setCarouselMessage(`Please Drive to selected carousel`);
    }
  }, []);

  const fetchVehicle = async () => {
    await VehicleService.getVehicleById(id).then((res) => {
      if (res) {
        setVehicle(res);
      }
    });
  };

  React.useEffect(() => {
    VehicleService.init(api);
    fetchVehicle();

    CarouselService.getCarouselForLocation(localStorage.userLocationId)
      .then((res) => {
        setCarousels(res);
        console.log(res, "demo dem demo");
      })
      .catch((res) => {
        console.log("No carousels there");
      });

    //localStorage.userLocationId
    CarouselService.getCarouselSlot({
      locationId: localStorage.userLocationId,
      vehicleId: id,
      carouselId: localStorage.carouselId,
    })
      .then((res) => {
        dispatch({ type: types.SET_USER_PIN, payload: res.userPin });
        setGeneratingPin(false);
      })
      .catch((res) => {
        console.log("No slot available. You can park in different carousel");
      });
  }, []);

  const handleSave = () => {
    history.push("/park/vehicle/" + id + "/park-info");
  };

  return (
    <AuthenticatedContainer>
      <>
        <Dialog
          open={openCarouselError}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Please Wait"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {carouselErrorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setopenCarouselError(false);
              }}
              color="primary"
            >
              OK
            </Button>
            {saving && (
              <CircularProgress size={14} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>

        <GridItem xs={12} sm={12} md={8} align="center">
          <Typography variant="h5" style={{ color: "#324152", padding: 15 }}>
            Your Parking Carousel Information
          </Typography>
        </GridItem>

        <GridItem xs={12} sm={12} md={8} align="center">
          <Typography
            variant="body1"
            style={{
              padding: 15,
              color: "green",
            }}
          >
            {(!generatingPin &&
              `${carouselMessage} | Your Parking PIN is: ${state.user.assignedPin}`) ||
              "Getting Pin for you..."}
          </Typography>
        </GridItem>

        <GridItem xs={12} sm={12} md={8}>
          <Box pb={5} className={classes.inputLabel}>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              fullWidth
              disabled={generatingPin}
              style={{
                marginTop: 20,
                color: "white",
              }}
            >
              Continue to park
            </Button>
            {saving && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Box>
        </GridItem>
      </>
    </AuthenticatedContainer>
  );
}

export default CarouselDetail;
