/* eslint-disable react/display-name */

import React from "react";
import { Container, Row } from "reactstrap";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import useAPI from "useAPI";
import NotificationService from "services/NotificationService";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import Loading from "components/Loading";
import moment from "moment";
import { Context } from "Store";
import types from "Reducer/types";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CloseIcon from "@material-ui/icons/Close";
import history from "utils/history";

export const Notifications = () => {
  const api = useAPI();
  // const [notifications, setNotifications] = React.useState([]);
  const [unread, setUnread] = React.useState([]);
  const [read, setRead] = React.useState([]);
  const { dispatch } = React.useContext(Context);
  const [loading, setLoading] = React.useState(true);

  const fetchNotifications = async () => {
    api.get("notifications").then((res) => {
      const read = res.data.filter((notification) => notification.read);
      const unRead = res.data.filter((notification) => !notification.read);
      // setNotifications(res.data);
      setLoading(false);
      setUnread(unRead);
      setRead(read);
    });
  };

  const getTime = (notificationDateTime) => {
    return moment(notificationDateTime).fromNow();
  };

  const handleAction = async (action) => {
    // api.get("notification-action/" + id).then((res) => {
    //   console.log("Action handled",res.data);
    // });
    const sessionId = action.split("/")[1];
    if (sessionId) {
      history.push("/session-detail/" + sessionId);
    }
  };
  const handleOnDelete = async (id) => {
    console.log("Delete", id);
    const readFiltered = read.filter((notification) => notification.id !== id);
    setRead(readFiltered);
    api.delete("notifications/" + id).then(() => {
      fetchNotifications();
    });
  };

  const handleMarkAsRead = async (id) => {
    const notificationItem = unread.filter(
      (notification) => notification.id === id
    );
    if (notificationItem.length) {
      const readedItems = notificationItem.map((item) => {
        item.read = 1;
        return item;
      });
      read.push(readedItems[0]);
      setRead(read);
    }
    const unReadFiltered = unread.filter(
      (notification) => notification.id !== id
    );
    setUnread(unReadFiltered);
    api.get("notifications/" + id + "/read").then(() => {
      fetchNotifications();
    });
  };

  React.useEffect(() => {
    NotificationService.init(api);
    fetchNotifications();
  }, [api]);

  React.useEffect(() => {
    dispatch({
      type: types.SET_NOTIFICATIONS,
      payload: unread.length,
    });
  }, [unread]);

  const renderUnRead = (notification) => {
    return (
      <ListItem
        divider
        key={notification.id}
        onClick={() => handleAction(notification.action)}
        style={{
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <ListItemText
          primary={notification.title}
          primaryTypographyProps={{ style: { color: "#111", fontSize: 12 } }}
          secondary={
            <React.Fragment>
              <span style={{ fontSize: 10 }}>
                {getTime(notification.created_at)}
              </span>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="show"
            onClick={() => handleMarkAsRead(notification.id)}
          >
            <VisibilityOffIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderRead = (notification) => {
    return (
      <ListItem
        divider
        key={notification.id}
        onClick={() => handleAction(notification.action)}
        style={{
          backgroundColor: "#e0e3e7",
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <ListItemText
          primary={notification.title}
          primaryTypographyProps={{ style: { color: "#111", fontSize: 12 } }}
          secondary={
            <React.Fragment>
              <span style={{ fontSize: 10 }}>
                {getTime(notification.created_at)}
              </span>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="show"
            onClick={() => handleOnDelete(notification.id)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <AuthenticatedContainer>
      <Container className="mb-5">
        <Row>
          <GridItem align="center" className="mb-2">
            <Typography
              variant="h5"
              color="primary"
              style={{ color: "#394253" }}
            >
              Notifications
            </Typography>
          </GridItem>
          <GridItem align="center" className="mb-2">
            <Typography
              variant="caption"
              color="primary"
              style={{ color: "#394253" }}
            >
              Unread ({unread.length})
            </Typography>
          </GridItem>
          <GridItem align="center" className="mb-2">
            <List style={{ maxWidth: 450 }}>
              {unread.map((notification) => {
                return renderUnRead(notification);
              })}
            </List>
          </GridItem>
          <GridItem align="center" className="mb-2">
            <Typography
              variant="caption"
              color="primary"
              style={{ color: "#394253" }}
            >
              Read ({read.length})
            </Typography>
          </GridItem>
          {(loading && <Loading />) || null}
          <GridItem align="center" className="mb-2">
            <List style={{ maxWidth: 450 }}>
              {read.map((notification) => {
                return renderRead(notification);
              })}
            </List>
          </GridItem>
        </Row>
      </Container>
    </AuthenticatedContainer>
  );
};

export default Notifications;
