import React from "react";

import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { Typography, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import PaymentService from "../../services/PaymentService";
import useAPI from "useAPI";
import { green, red } from "@material-ui/core/colors";
import ReceiptIcon from "@material-ui/icons/Receipt";

import moment from "moment";

// import Autocomplete from "@material-ui/lab/Autocomplete";

// const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  // ...styles,
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    color: "#324152",
    marginTop: 20,
    marginBottom: 80,
  },
  tabStyle: { color: "#324152", marginTop: 20 },
  fab: {
    position: "absolute",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

function CardList() {
  const classes = useStyles();

  const api = useAPI();
  const [transactions, setTransactions] = React.useState([]);

  const fetchTransactions = async () => {
    const resposne = await PaymentService.getTransactions();
    setTransactions(resposne);
  };
  const getDate = (dateTime) => {
    // return new Date(dateTime);
    return moment(dateTime).format("L");
  };
  React.useEffect(() => {
    PaymentService.init(api);
    fetchTransactions();
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const viewInvoice = (transaction) => {
    PaymentService.getInvoiceLink(transaction).then((result) => {
      openInNewTab(result);
    });
  };

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Typography variant="h5" style={{ color: "#324152" }}>
          Payment History
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align="center">
        <div className={classes.root}>
          <List>
            {transactions &&
              transactions.map((transaction) => (
                <ListItem
                  divider
                  key={transaction.id}
                  button
                  value={transaction}
                  style={{
                    backgroundColor:
                      transaction.status === "succeeded"
                        ? green[100]
                        : red[100],
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <ListItemText
                    primary={` Amount: $${transaction.price} for ${
                      transaction.parkingSession.duration || 1
                    } ${
                      transaction.parkingSession.duration > 1
                        ? "minutes"
                        : "minute"
                    }`}
                    primaryTypographyProps={{
                      style: { color: "#111", fontSize: 14 },
                    }}
                    secondary={
                      <React.Fragment>
                        Pin: {transaction.parkingSession.user_vehicle_pin}
                        <br />
                        Date: {getDate(transaction.parkingSession.start_time)}
                        <br />
                        Vehicle: {transaction.parkingSession.vehicle_name}
                        <br />
                        Status: {String(transaction.status).toUpperCase()}
                        <br />
                        {(transaction.parkingSession.discount_id &&
                          "Discount Applied") ||
                          null}
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => viewInvoice(transaction)}>
                      <ReceiptIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </div>
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default CardList;
