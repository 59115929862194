import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Retrive from "./views/Retrive";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import ScannedCarousel from "./views/LandingPage/CarouselScanned";
import Park from "./views/Park";
import ProtectedRoute from "./views/Auth/ProtectedRoute";
import Vehicles from "./views/Settings/Vehicles";
import Settings from "./views/Settings/Settings";
import TicketDetail from "./views/TicketDetail";
import Plan from "./views/Plan";
import ConfirmPlan from "./views/ConfirmPlan";
import PaymentButton from "./views/Settings/PaymentButton";
import PaymentCards from "./views/Settings/PaymentCards";
import Transaction from "./views/Settings/Transactions";
import Tickets from "./views/Settings/Tickets";
import VehicleStatus from "./views/VehicleStatus";
import BrandBar from "components/BrandBar";
import BottomTabs from "components/BottomTabs";
import { Notifications } from "views/Notifications";
import SelectVehicle from "views/SelectVehicle";
import NewVehicle from "views/Settings/NewVehicle";
import EditVehicle from "views/Settings/EditVehicle";
import ParkingInfo from "views/ParkingInfo";
import CarouselDetail from "views/CarouselDetail";
import NewPaymentCard from "views/Settings/NewPaymentCard";
import EditCard from "views/Settings/EditCard";
import ParkingHistory from "views/Settings/ParkingHistory";
import Checkout from "views/Checkout";
import SessionDetail from "views/SessionDetail";
import ErrorPage from "views/ErrorPage";
import { getConfig } from "config";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import TestMap from "views/Settings/TestMap";
import NotificationSettings from "views/Settings/NotificationSettings";
import NotifyAttendant from "views/NotifyAttendant";
import SubscriptionTransactions from "views/Settings/SubscriptionTransactions";

initFontAwesome();
const config = getConfig();
const stripePromise = loadStripe(config.stripePublishKey);
const App = () => {
  const { isLoading, error, isAuthenticated } = useAuth0();

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  const theme = createMuiTheme({
    palette: {
      type: "light",
      primary: orange,
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <div id="app" className="d-flex flex-column h-100">
              <BrandBar />
              <Container
                className="flex-grow-1"
                style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 80 }}
              >
                <Switch>
                  <Route path="/ticket/:id" exact component={TicketDetail} />
                  <ProtectedRoute path="/" exact component={Home} />
                  <ProtectedRoute path="/profile" component={Profile} />
                  <ProtectedRoute path="/reserve" component={Plan} />
                  <ProtectedRoute
                    path="/confirm-reserve"
                    component={ConfirmPlan}
                  />
                  <ProtectedRoute
                    path="/park/vehicle/select"
                    component={SelectVehicle}
                  />
                  <ProtectedRoute
                    path="/park/vehicle/new"
                    component={NewVehicle}
                  />
                  <ProtectedRoute
                    path="/vehicles/manage"
                    component={SelectVehicle}
                  />
                  <ProtectedRoute path="/vehicles/new" component={NewVehicle} />
                  <ProtectedRoute
                    path="/park/vehicle/:id/edit"
                    component={EditVehicle}
                  />
                  <ProtectedRoute
                    path="/vehicle/:id/edit"
                    component={EditVehicle}
                  />
                  <ProtectedRoute
                    path="/park/vehicle/:id/park-info"
                    component={ParkingInfo}
                  />
                  <ProtectedRoute
                    path="/park/carousel-detail/:id"
                    component={CarouselDetail}
                  />
                  <ProtectedRoute
                    path="/park/vehicle/:vid/card/new"
                    component={NewPaymentCard}
                  />
                  <ProtectedRoute
                    path="/carousel/:token"
                    component={ScannedCarousel}
                  />
                  <ProtectedRoute path="/park" component={Park} />
                  <ProtectedRoute path="/retrive" component={Retrive} />
                  <ProtectedRoute path="/vehicles" component={Vehicles} />
                  <ProtectedRoute path="/settings" component={Settings} />
                  <ProtectedRoute
                    path="/payment-button"
                    component={PaymentButton}
                  />
                  <ProtectedRoute
                    path="/payment-card/:id/edit"
                    component={EditCard}
                  />
                  <ProtectedRoute
                    path="/payment-card/new"
                    component={NewPaymentCard}
                  />
                  <ProtectedRoute
                    path="/payment-cards"
                    component={PaymentCards}
                  />
                  <ProtectedRoute
                    path="/notifications"
                    component={Notifications}
                  />
                  <ProtectedRoute
                    path="/external-api"
                    component={ExternalApi}
                  />
                  <ProtectedRoute
                    path="/transactions"
                    component={Transaction}
                  />
                  <ProtectedRoute
                    path="/subscription-transactions"
                    component={SubscriptionTransactions}
                  />
                  <ProtectedRoute path="/tickets" component={Tickets} />
                  <ProtectedRoute path="/checkout" component={Checkout} />
                  <ProtectedRoute
                    path="/session-detail/:id"
                    component={SessionDetail}
                  />
                  <ProtectedRoute path="/test-map" component={TestMap} />
                  <ProtectedRoute
                    path="/vehicle-status"
                    component={VehicleStatus}
                  />
                  <ProtectedRoute
                    path="/parking-history"
                    component={ParkingHistory}
                  />
                  <ProtectedRoute
                    path="/notify-attendant"
                    component={NotifyAttendant}
                  />
                  <ProtectedRoute
                    path="/notifications-settings"
                    component={NotificationSettings}
                  />
                </Switch>
              </Container>
              {(isAuthenticated && <BottomTabs />) || null}
            </div>
          </Router>
        </MuiPickersUtilsProvider>
      </Elements>
    </ThemeProvider>
  );
};

export default App;
