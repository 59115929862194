/* eslint-disable react/prop-types */
import React from "react";

// core components
import "react-credit-cards/es/styles-compiled.css";
import useAPI from "useAPI";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";

function CheckoutForm() {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = React.useState(null);
  const api = useAPI();

  React.useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Demo total",
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        console.log("6666666,", result); // applepay, googlepay
        if (result) {
          setPaymentRequest(pr);
          console.log("5555555555555", paymentRequest);
          pr.on("paymentmethod", async (ev) => {
            console.log("111111111", ev);
            // Confirm the PaymentIntent without handling potential next actions (yet).
            const clientSecret = await api.post(`/create-payment-intent`);
            console.log("4444444444", clientSecret);
            const {
              paymentIntent,
              error: confirmError,
            } = await stripe.confirmCardPayment(
              clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            );

            if (confirmError) {
              ev.complete("fail");
            } else {
              ev.complete("success");
              if (paymentIntent.status === "requires_action") {
                // OTP
                const { error } = await stripe.confirmCardPayment(clientSecret);
                if (error) {
                  alert("Payment Fail.");
                } else {
                  alert("The payment has succeeded.");
                }
              } else {
                alert("The payment has succeeded.");
              }
            }
          });
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    return <PaymentRequestButtonElement options={{ paymentRequest }} />;
  }
  return "Apple Pay /Google Pay Not Supported.";
}

export default CheckoutForm;
