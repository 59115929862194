/* eslint-disable react/display-name */

import React from "react";
import { Context } from "Store";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Container, Row, Col } from "reactstrap";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import useAPI from "useAPI";
import UserService from "../services/UserService";
import OtpService from "../services/OtpService";
import InputMask from "react-input-mask";
import { useLocation } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
// import Alert from "@material-ui/lab/Alert";
import MuiAlert from "@material-ui/lab/Alert";
import history from "utils/history";
import OtpInput from "react-otp-input";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  ...styles,
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const ProfileComponent = () => {
  const classes = useStyles();
  const { user } = useAuth0();
  const location = useLocation();
  const states = require("./Settings/States");

  const { state, dispatch } = React.useContext(Context);
  const { tncChange } = state;

  const [saving, setSaving] = React.useState(false);
  const [systemUser, setSystemUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openOtp, setOpenOtp] = React.useState(false);
  const [otp, setOtp] = React.useState("");

  const api = useAPI();

  const [values, setValues] = React.useState({});
  const [errors, setErrors] = React.useState({
    phone: "",
    otp: "",
  });

  const verifyOtp = () => {
    OtpService.verifyOtp(otp).then((res) => {
      console.log("otpRes", res);
      if (res === true) {
        // verified
        fetchUser();
        setOtp("");
        setOpenOtp(false);
        if (location?.state?.isRedirectFromHome) {
          setTimeout(() => {
            history.push({
              pathname: "/notifications-settings",
              state: { isRedirectFromProfile: true },
            });
          }, 1000);
        }
      } else {
        setErrors({ ...errors, otp: "Please Enter Valid OTP" });
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseOtpDialog = () => {
    console.log("OTP:", otp);
    setOpenOtp(false);
  };

  const fetchUser = async () => {
    api.get("user").then((res) => {
      let sysUser = res.data;
      console.log(sysUser);
      Object.keys(sysUser).forEach((key) => {
        sysUser[key] = sysUser[key] ? sysUser[key] : "";
      });
      setValues({ ...sysUser });
      setSystemUser(sysUser);
    });
  };

  React.useEffect(() => {
    UserService.init(api);
    OtpService.init(api);
    fetchUser();
  }, [api, tncChange]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleOtp = (otp) => {
    setOtp(otp);
  };

  const handleSave = async () => {
    let error = 0;
    let newErrors = errors;
    ["phone", "name"].forEach((field) => {
      if (values[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });

    setErrors({ ...newErrors });

    console.log(error);
    if (error) return;
    setSaving(true);
    UserService.updateUser(values).then((res) => {
      // if phone is already verified & not changed then no need to open otp dialog box.
      if (res.otpSent === true) {
        setOpenOtp(true);
      } else {
        setTimeout(() => {
          if (location?.state?.isRedirectFromHome) {
            setTimeout(() => {
              history.push({
                pathname: "/notifications-settings",
                state: { isRedirectFromProfile: true },
              });
            }, 1000);
          } else {
            // redirect to home
            history.push({
              pathname: "/",
            });
          }
        }, 1000);
      }
      setOpen(true);
      setSaving(false);
      fetchUser();
    });
  };

  if (!systemUser) {
    return (
      <AuthenticatedContainer>
        <Container className="m-5">
          <Row className="align-items-center profile-header mb-5 text-center text-md-left">
            <Col md={2}>
              <img
                src={user.picture}
                alt="Profile"
                className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
              />
            </Col>
            <Col>
              <p className="lead text-muted">{user.email}</p>
            </Col>
          </Row>
        </Container>
      </AuthenticatedContainer>
    );
  }

  return (
    <AuthenticatedContainer>
      <Container className="m-5">
        <Row>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={openOtp}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseOtpDialog}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h4>Enter OTP</h4>
              <h5>Please Verify Your Phone Number {values["phone"]}</h5>
              <h6 style={{ color: "red" }}>{errors.otp}</h6>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={{ justifyContent: "center" }}
            >
              <OtpInput
                value={otp}
                onChange={handleOtp}
                numInputs={4}
                separator={<span>-</span>}
                inputStyle={{
                  width: "30px",
                  height: "30px",
                  margin: "15px",
                  fontSize: "15px",
                  borderRadius: 4,
                  border: "1px solid rgba(0,0,0,0.3)",
                }}
                isInputNum={true}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseOtpDialog} variant="outlined">
                Cancel
              </Button>
              <Button onClick={verifyOtp} color="primary" variant="outlined">
                Verify
              </Button>
            </DialogActions>
          </Dialog>
        </Row>
        <Row className="align-items-center profile-header mb-5 text-center text-md-left">
          <Col md={2}>
            <img
              src={user.picture}
              alt="Profile"
              className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
            />
          </Col>
          <Col>
            <p className="lead text-muted">{user.email}</p>
          </Col>
        </Row>
        <Row>
          <Box pb={5}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name*"
              variant="outlined"
              value={values.name}
              onChange={handleChange("name")}
              type="text"
              fullWidth
              error={errors.name === "error"}
            />

            <InputMask
              mask="+1 (999) 999 9999"
              value={values.phone}
              disabled={false}
              onChange={handleChange("phone")}
              maskChar=" "
            >
              {() => (
                <TextField
                  error={errors.phone === "error"}
                  autoFocus
                  margin="dense"
                  id="phone"
                  label="Phone*"
                  variant="outlined"
                  value={values.phone}
                  InputProps={{
                    endAdornment: values.phone_verified ? (
                      <CheckCircleIcon style={{ color: "#006600" }} />
                    ) : (
                      <Tooltip title="Unverified Phone Number" aria-label="add">
                        <IconButton>
                          {" "}
                          <ErrorOutlinedIcon style={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  type="text"
                  fullWidth
                />
              )}
            </InputMask>
            <TextField
              autoFocus
              margin="dense"
              id="address"
              label="Address"
              value={values.address}
              variant="outlined"
              onChange={handleChange("address")}
              type="text"
              fullWidth
              multiline
              rows={1}
            />

            <TextField
              autoFocus
              margin="dense"
              id="city"
              label="City"
              value={values.city}
              variant="outlined"
              onChange={handleChange("city")}
              type="text"
              fullWidth
            />

            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <InputLabel id="state-id">State</InputLabel>
              <Select
                label="State"
                value={values.state}
                required
                onChange={handleChange("state")}
              >
                <MenuItem
                  disabled
                  value=""
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose State
                </MenuItem>

                {states &&
                  states.map((state) => (
                    <MenuItem
                      value={`${state}`}
                      key={`${state}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${state}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {/* <TextField
              autoFocus
              margin="dense"
              id="state"
              label="State"
              variant="outlined"
              value={values.state}
              onChange={handleChange("state")}
              type="text"
              fullWidth
            /> */}

            {/* <TextField
              autoFocus
              margin="dense"
              id="zipcode"
              label="Zipcode"
              variant="outlined"
              value={values.zipcode}
              onChange={handleChange("zipcode")}
              type="number"
              fullWidth
            /> */}

            <InputMask
              mask="99999"
              value={values.zipcode}
              disabled={false}
              onChange={handleChange("zipcode")}
              maskChar=" "
            >
              {() => (
                <TextField
                  autoFocus
                  margin="dense"
                  id="zipcode"
                  label="Zip Code"
                  variant="outlined"
                  value={values.zipcode}
                  type="text"
                  fullWidth
                />
              )}
            </InputMask>

            {/* <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              variant="outlined"
              value={values.email}
              onChange={handleChange("email")}
              type="email"
              disabled
              fullWidth
            /> */}

            {/* <TextField
              autoFocus
              margin="dense"
              id="phone"
              label="Phone"
              variant="outlined"
              value={values.phone}
              onChange={handleChange("phone")}
              type="text"
              fullWidth
            /> */}

            {/* <TextField
              autoFocus
              margin="dense"
              id="licenseNumber"
              label="License #"
              variant="outlined"
              value={values.licenseNumber}
              onChange={handleChange("licenseNumber")}
              type="text"
              fullWidth
            /> */}

            {/* <TextField
              autoFocus
              margin="dense"
              id="licenseState"
              label="License State"
              variant="outlined"
              value={values.licenseState}
              onChange={handleChange("licenseState")}
              type="text"
              fullWidth
            /> */}
            {/* <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <InputLabel id="license-state-id">LicenseState</InputLabel>
              <Select
                label="LicenseState"
                value={values.licenseState}
                required
                onChange={handleChange("licenseState")}
              >
                <MenuItem
                  disabled
                  value={""}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose License State
                </MenuItem>

                {states &&
                  states.map((state) => (
                    <MenuItem
                      value={`${state}`}
                      key={`${state}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${state}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
            <div className={classes.wrapper}>
              <Button
                onClick={handleSave}
                variant="contained"
                disabled={saving}
                color="primary"
                fullWidth
                className="mt-3"
              >
                Save
              </Button>
              {saving && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            <div>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert severity="success" onClose={handleClose}>
                  Profile Updated Successfully
                </Alert>
              </Snackbar>
            </div>
          </Box>
        </Row>
        {/* <Row>
          <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
        </Row> */}
      </Container>
    </AuthenticatedContainer>
  );
};

export default ProfileComponent;
