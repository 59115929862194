import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import { Typography } from "@material-ui/core";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import VehicleForm from "./VehicleForm";
import { useLocation } from "react-router-dom";

function NewVehicle() {
  const location = useLocation();

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Typography variant="h5" style={{ color: "#324152" }}>
          New Vehicle
        </Typography>
      </GridItem>
      <VehicleForm
        userLocationId={location.state ? location.state.userLocationId : null}
      />
    </AuthenticatedContainer>
  );
}

export default NewVehicle;
